import { AppMode } from "./SharedClasses";

type AccessMapType = {
  [key in AppMode]: string[];
};
//the user types usertype[0]="Administrator"
export const userType: string[] = ["Administrator", "Study coordinator", "Researcher", "Uploader"];

export function checkUserAccess(userType:string,mode:AppMode):boolean{
  var access=AccessMap[mode].includes(userType);
  return access;
} 
//update settings modes(labels user )
export const AccessMap: AccessMapType = {
  [AppMode.Home]: [...userType], //0
  [AppMode.Search]: [...userType], //1
  [AppMode.Export]: [userType[0], userType[1], userType[2]], //2
  [AppMode.Upload]: [userType[0], userType[1], userType[3]], //3
  [AppMode.Settings]:[...userType], //4                    //settings slideout
  [AppMode.User]: [userType[0], userType[1]], //5                      //window of all users in settings
  [AppMode.AddUser]: [userType[0], userType[1]], //6                   //add user screen in user in settings
  [AppMode.Study]: [userType[0]], //7                                  //administer studies in settings
  [AppMode.Default]: [...userType], //8
  [AppMode.Labels]: [userType[0]], //9                                 //edit labels in settings
  [AppMode.Report]: [...userType], //10                                //generate a rapport
  [AppMode.DashboardControl]: [userType[0]], //11                      //delete studies procedures ... in setting
  [AppMode.Edit]:[userType[0], userType[1]], //12
  [AppMode.SelectedHome]:[userType[0], userType[1], userType[2]], //13 //charts for the selected study
  [AppMode.UploadHome]: [userType[3]], //14
  [AppMode.UsageHistory]: [...userType],//15
  [AppMode.ImportHistory]: [...userType],//16
  [AppMode.CurrentLogons]: [...userType],//17
  [AppMode.LabelMap]: [userType[0], userType[1]],//18
  [AppMode.UserHistorySummary]: [userType[0]],//19
  [AppMode.SitesSummary]: [userType[0], userType[1], userType[2] ],//20 //Uploaders cant see measurements, site number of procedures progress bar.
  [AppMode.StudyUsageHistory]: [userType[0]]//21
};




