import React from "react";

export class ZoomButtons extends React.Component<
    {
        onZoomOffsetChange: (zoomFactor: number,offset: number) => void;

        initialOffset?: number;
        zoomIncrement?: number; //it will be the scaling factor when pressing the zoom in button, the divisor for zoomout
        minZoom?: number; //can set a min or max as a decimal number equal to the scaling factor, e.g. minZoom=0.2 is 20%
        maxZoom?: number; 
        offSetIncrement?: number;
        offsetAddend?: number; 
    },
    {
        zoomFactor: number;
        offset: number;
        
    }
> {
    constructor(props: any) {
        super(props);
        this.state = {
            zoomFactor: 1, // Initial zoom level (100%)
            offset: props.initialOffset || 0,
        }
    }

    zoomIn = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        this.setState(prevState => {
            const increment = this.props.zoomIncrement || 1.1;
            const maxZoom = this.props.maxZoom || Infinity;
            const newZoomFactor = Math.min(maxZoom, prevState.zoomFactor * increment);
            this.props.onZoomOffsetChange(newZoomFactor, this.state.offset);
            return { zoomFactor: newZoomFactor };
        });
    }

    zoomOut = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        this.setState(prevState => {
            const increment = this.props.zoomIncrement || 1.1;
            const minZoom = this.props.minZoom || 0.1;
            const newZoomFactor = Math.max(minZoom, prevState.zoomFactor / increment);
            this.props.onZoomOffsetChange(newZoomFactor, this.state.offset);
            return { zoomFactor: newZoomFactor };
        });
    }

    resetZoomOffset = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ zoomFactor: 1, offset: 0 });
        this.props.onZoomOffsetChange(1,0);
    }

    moveUp = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        this.setState(prevState => {
            const newOffset = prevState.offset + (this.props.offsetAddend?this.props.offsetAddend:10) / prevState.zoomFactor;
            this.props.onZoomOffsetChange(this.state.zoomFactor,newOffset);
            return { offset: newOffset };
        });
    }

    moveDown = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        this.setState(prevState => {
            const newOffset = prevState.offset - (this.props.offsetAddend?this.props.offsetAddend:10) / prevState.zoomFactor;
            this.props.onZoomOffsetChange(this.state.zoomFactor,newOffset);
            return { offset: newOffset };
        });
    }

    render() {
        return (
            <div className="zoom-buttons">
                <button className="intui-button zoom-in" onClick={this.zoomIn} title="Zoom in">+</button>
                <button className="intui-button zoom-out" onClick={this.zoomOut} title="Zoom out">-</button>
                <button className="intui-button zoom-reset" onClick={this.resetZoomOffset} title="Reset">↺</button>
                <button className="intui-button move-up" onClick={this.moveUp} title="Move scale up">⤉</button>
                <button className="intui-button move-down" onClick={this.moveDown} title="Move scale down">⤈</button>
                <span className="zoom-level">Zoom: {Math.round(this.state.zoomFactor * 100)}%</span>
            </div>
        );
    }
}