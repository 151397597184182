import React, {RefObject} from "react";
import { DataHelper } from "../Helpers/DataHelper";
import MultiFileBrowser from "../Components/MultiFileBrowser";
import { PreviewExportModel } from "../Models/PreviewExportModel";
import { PreviewExportMetadataModel } from "../Models/PreviewExportMetadataModel";
import { PreviewProcedureModel } from "../Models/PreviewProcedureModel";
import DataGrid from "../Components/DataGrid";
import { PreviewMeasurement } from "../Models/PreviewMeasurement";
import { DataGridRow } from "../Helpers/SharedClasses";
import { InfoBox } from "../Components/InfoBox";
import { Progressbar } from "../Components/Progressbar";
import { ProgressModel } from "../Models/ProgressModel";
import { StudyModel } from "../Models/StudyModel";
import { ErrorWindow } from "../Components/ErrorWindow";
import { MeasurmentThumbnail } from "../Components/MeasurementThumbnail";
import { UserAccessModel } from "../Models/UserAccessModel";
import { userType } from "../Helpers/AccessMap";
import { CryptographyHelper } from "../Helpers/CryptographyHelper";
import { PreviewProcedureMetadataModel } from "../Models/PreviewProcedureMetadataModel";
import { PreviewMetadataMeasurement } from "../Models/PreviewMetadataMeasurement";
import { InputItem } from "../Components/InputItem";

export class UploadWindow extends React.Component<
  {  dataHelper: DataHelper, studySelected: StudyModel, user: UserAccessModel, closeClicked: () => void, refreshStudyRequested: ()=> void  },
  {
    progressText: string,
    dynamicNames: any,
    progressTextDetails: string,
    uploadStatus: string,
    previewReady: boolean,
    uploadPreview: PreviewExportModel,
    cachedData: File[],
    uploadLoaded: number,
    uploadTotal: number,
    status: string;
    uploadedData: number[],
    totalFiles: number, 
    fileProgress: number,
    initialUploadStatus: string,
    progressMetadata: PreviewExportMetadataModel
  }
> {
  errorWindow:  RefObject<ErrorWindow>;
  infoBox: RefObject<InfoBox>;
  fileBrowserMulti: RefObject<MultiFileBrowser>;
  pwd: string;
  constructor(props: any) {
      super(props);
      this.errorWindow = React.createRef();
      this.infoBox = React.createRef();
      this.fileBrowserMulti = React.createRef();
      this.pwd = "";
    this.state = {
        dynamicNames: {
          patientId: "Patient id",
          anatomicalLocations: "Anatomical Locations",
          createdDate: "Date"
          
        },
        progressText: "",
        totalFiles: 0,
        fileProgress: 0,
        uploadStatus: "",
        initialUploadStatus: "",
        progressTextDetails: "",
        previewReady: false,
        uploadPreview:  {
          id: "",
          importLabel: "",
          importDate: Date() as any,
          filename: "",
          hospitalName: "",
          serialNumber: "",
          department: "",
          softwareVersion: "", 
          exportDate: Date() as any,
          procedures: [],
          studySelected: props.studySelected.id,
          confirmEmail: false,
          includePatientId: false,
          encryptionEnabled: false,
          includeDateTime: false,
          includeGenderHeightWeightAge: false
        },
        
        cachedData: [],
        uploadLoaded:0,
        uploadTotal:0,
        status: "uploadReady",
        uploadedData:[],
        progressMetadata: {
          studyId:"",
          id: "",
          procedures: []
        }
    }
  }
  intervalSet: any;
  startPingProcess = ()=>
  {
      this.intervalSet = setInterval(()=>{
        this.pingProgress((keepRunning: boolean) =>{
          if (!keepRunning)
            clearInterval(this.intervalSet);
        });
      }, 1000)

  }
  progressUpdatedOther = (arg0: ProgressEvent, progressMessage: string = "")=>
  {
  }
  progressUpdated = (arg0: ProgressEvent, progressMessage: string = "")=>
  {
    
    if (arg0)
    {
      this.setState({uploadLoaded: arg0.loaded, uploadTotal: arg0.total});
    if (arg0.loaded === arg0.total)
    {
      if (progressMessage !== "Process")
      {
      
          this.startPingProcess();
          this.setState({
            uploadStatus: "",
            progressText: "Upload finishing up. Starting processing..." 
          });
      }else
      {
        this.setState({
          uploadStatus: "",
          progressText: "Processing completed." 
        });
      }
    
    }else
    
    {
      if (progressMessage !== "Process")
      {
      this.setState({
        uploadStatus: "Uploading",
        progressText: "Upload progress: " + (arg0.loaded/1000000).toFixed(2)  +" MB/"+ (arg0.total/1000000).toFixed(2)  +" MB"
      });
    }else
    {
      this.setState({
        uploadStatus: "Processing",
        progressText: "Processing measurements"
      });
    }
    }
  }
  else
  {
  let progressErrorsDetails = progressMessage.split("#");
  this.setState({
    uploadStatus: "",
    progressText: progressErrorsDetails[0],
    progressTextDetails: progressErrorsDetails.length > 1 ? progressErrorsDetails[1] : ""
  });
  }
      
  }



  GetDateFromElement = (element: Element | undefined) : Date =>
  {
    if (element)
    {
    let year = 0;
    let month = 1;
    let day = 0;
    let hour = 0;
    let minute = 0;
    let second = 0;
      if (element.getElementsByTagName("Year").length > 0)
      {
        year =  parseInt(element.getElementsByTagName("Year")[0].childNodes[0].nodeValue as string);
      }
      if (element.getElementsByTagName("Month").length > 0)
      {
        month =  parseInt(element.getElementsByTagName("Month")[0].childNodes[0].nodeValue as string);
      }
      if (element.getElementsByTagName("Day").length > 0)
      {
        day =  parseInt(element.getElementsByTagName("Day")[0].childNodes[0].nodeValue as string);
      }
      if (element.getElementsByTagName("Hour").length > 0)
      {
        hour =  parseInt(element.getElementsByTagName("Hour")[0].childNodes[0].nodeValue as string);
      }
      if (element.getElementsByTagName("Minute").length > 0)
      {
        minute =  parseInt(element.getElementsByTagName("Minute")[0].childNodes[0].nodeValue as string);
      }
      if (element.getElementsByTagName("Second").length > 0)
      {
        second =  parseInt(element.getElementsByTagName("Second")[0].childNodes[0].nodeValue as string);
      }
        let dateOut = new Date(year, month-1, day, hour, minute, second, 0);
      return dateOut;
    }
    else
    //TODO: when the date should not return todays date!
    return new Date();
  }

  ParseNumber (valueIn: string): number 
  {
    return parseFloat(valueIn.replace(/,/, '.'));
  }

  ParseDate  (valueIn: string): Date 
  {
    let basicParse = Date.parse(valueIn);
    if (basicParse && basicParse !== 0 && !Number.isNaN(basicParse))
    {
        return new Date(basicParse);
    }else
    {
      //This is dodgy but lets try tweak the date a little and then parse it. This will work for this format: 21.03.2023 12:36:31
      //let correctedString = valueIn.split(".").join("-").split(" ").join("T");
      let day = valueIn.substring(0, 2);
      let month = valueIn.substring(3, 5);
      let year = valueIn.substring(6, 10);
      let time = valueIn.substring(11, 19);
      //This replace "." in time with ":" to get it on the correct format
      if (time.indexOf(".") !== -1){
        let replacedTime = time.replace(/\./g, ":");
        let correctedString = year + "-" + month + "-" + day + "T" + replacedTime;
        basicParse = Date.parse(correctedString);
      }
      else{
      let correctedString = year + "-" + month + "-" + day + "T" + time;
      basicParse = Date.parse(correctedString);}
      return new Date(basicParse);
    }
  }


  GetIndexValue(element: Element, nodeName: string): number
  {
        if (element && element.children.length > 0)
        {
            for (let index = 0; index < element.children.length; index++) {
              const childelement = element.children[index];
              if (this.getElementValue(childelement, "Name") === nodeName)
                  return this.ParseNumber(this.getElementValue(childelement, "Value"));
            }
        }
        return 0.0;
  }

  LookUpNames = (element: Element, nodeName: string) =>
  {
    let outputLookup: any = {};
    let nodesList = this.getFirstChildElement(element, nodeName + "s") as Element;
    if (nodesList)
    {
    for (let index = 0; index < nodesList.children.length; index++) {
      const childelement =  nodesList.children[index] as Element;

      outputLookup[childelement.getAttribute("GUID") as string] = this.getElementValue(childelement,"Name");
    }
  }
    return outputLookup;
       
  }

  LookUpNamesDetail = (element: Element, nodeName: string) =>
  {
    let outputLookup: any = {};
    let nodesList = this.getFirstChildElement(element, nodeName + "s") as Element;
    if (nodesList)
    {
    for (let index = 0; index < nodesList.children.length; index++) {
      const childelement =  nodesList.children[index] as Element;

      outputLookup[childelement.getAttribute("GUID") as string] = {
        id: this.getElementValue(childelement,"Name"),
        name: this.getElementValue(childelement,"Name"),
        description: this.getElementValue(childelement,"OriginalName"),
        isAttribute: this.getElementValue(childelement,"IsAttribute") ?  this.getElementValue(childelement,"IsAttribute") : false,
        componentType: this.getElementValue(childelement,"ComponentType"),
        type: ""
      }
    }
  }
    return outputLookup;
       
  }

  getElementValue = (element: Element, nodeName: string): string=>
  {
    for (let index = 0; index < element.getElementsByTagName(nodeName).length; index++) {
      const childelement = element.getElementsByTagName(nodeName)[index];
      if (childelement.parentNode === element)
      {
        return childelement.textContent as string;
      }
    }
    return "";
  }

  getFirstChildElement = (element: Element | undefined, nodeName: string): Element | undefined =>
  {
    if (element)
    {
  
    for (let index = 0; index < element.getElementsByTagName(nodeName).length; index++) {
      const childelement: Element = element.getElementsByTagName(nodeName)[index];
      if (childelement.parentNode === element)
      {
        return childelement;
      }
    }    
  }
  
  }

  getChildElements = (element: Element | undefined, nodeName: string): Element[] =>
  {
    let childrenOut: Element[] = [];
    if (element)
    {
  
    for (let index = 0; index < element.getElementsByTagName(nodeName).length; index++) {
      const childelement: Element = element.getElementsByTagName(nodeName)[index];
      if (childelement.parentNode === element)
      {
        childrenOut.push(childelement);
      }
    }   
 
  }
  return childrenOut; 
  }


  processProcedureNode = (patientElement: Element, indexProc: number, systemId: string, hospitalName: string, AnatomicalLocationLookup: any, VesselComponentLookup: any, VesselComponentLookupDetail: any,  MeasurementTypeLookup: any, LabelsLookup: any, otherfiles: File[]): PreviewProcedureModel | undefined =>
  {
    
      //The procedureElement is the PatientNode
      let commentsWorking = this.getElementValue(patientElement, "Comments");
    //REQUEST! Change this back to PatientIdentifier!!!!!
      let patientidWorking = this.getElementValue(patientElement, "Name");

      let heightWorking = this.ParseNumber(this.getElementValue(patientElement, "Height"));
      let weightWorking = this.ParseNumber(this.getElementValue(patientElement, "Weight"));
      let genderWorking = this.ParseNumber(this.getElementValue(patientElement, "Gender"));
      let dobWorking = this.getFirstChildElement(patientElement, "BirthDate") as Element;
      let birthYearWorking = 0;
      if (dobWorking.getElementsByTagName("Year").length > 0)
      {
        try {

        
        let year =  parseInt(dobWorking.getElementsByTagName("Year")[0].childNodes[0].nodeValue as string);
        
        birthYearWorking = year;
        }catch (exparse)
        {
          console.log("Parsing date failed.")
        }
      }
      //Assume single case per patient?
      let caseNode = this.getFirstChildElement(this.getFirstChildElement(patientElement, "Cases"), "Case");// patientElement.getElementsByTagName("Cases")[0].getElementsByTagName("Case")[0];
      if (caseNode)
      {
      let caseId = caseNode.getAttribute("GUID");
      let caseComments = this.getElementValue(caseNode, "Comments");

      if (commentsWorking && caseComments)
        caseComments = commentsWorking + ". " + caseComments;
      else if (!caseComments)
        caseComments = commentsWorking;
        
      let AnatomicalLocations: string[] = [];
      let startTime = this.GetDateFromElement(this.getFirstChildElement(caseNode, "StartTime") as Element);
      let flowMeasurementsCount = 0;
      let imagingMeasurementsCount = 0;
      let missingData = false;
      let measurementsList: PreviewMeasurement[] = [];
      let measurementSets = this.getChildElements(this.getFirstChildElement(caseNode, "MeasurementSets"), "MeasurementSet");
      let measurementCount = 0;
      for (let index = 0; index < measurementSets.length; index++) {
        const measurementSetElement = measurementSets[index] as Element;
        
        let measurementSetIdCurrent = measurementSetElement.getAttribute("GUID") as string;
        let measurementSetIndexCurrent = index;
        let measurementQualified = this.getElementValue(measurementSetElement, "Qualified");
        let measurements = this.getChildElements(this.getFirstChildElement(measurementSetElement, "Measurements"), "Measurement");
        let MapMean = -1;
        let MapMax = -1;
        let MapMin = -1;
        let BpmMean = -1;
        let BpmMax = -1;
        let BpmMin = -1;

        //First lets try get the MAP and BPM from the other measurements
        for (let index = 0; index < measurements.length; index++) {
          const measurementElement = measurements[index] as Element;
          let measurementType = MeasurementTypeLookup[(this.getFirstChildElement(measurementElement, "MeasurementType") as Element).getAttribute("GUID") as string];
          if (measurementType === "Ecg")
          {
            BpmMean = this.GetIndexValue(this.getFirstChildElement(measurementElement, "Indices") as Element, "Mean");
            BpmMax = this.GetIndexValue(this.getFirstChildElement(measurementElement, "Indices") as Element, "Max");
            BpmMin = this.GetIndexValue(this.getFirstChildElement(measurementElement, "Indices") as Element, "Min");
          }
          if (measurementType === "Pressure")
          {
            MapMean = this.GetIndexValue(this.getFirstChildElement(measurementElement, "Indices") as Element, "Mean");
            MapMax = this.GetIndexValue(this.getFirstChildElement(measurementElement, "Indices") as Element, "Max");
            MapMin = this.GetIndexValue(this.getFirstChildElement(measurementElement, "Indices") as Element, "Min");
          }
        }


      for (let index = 0; index < measurements.length; index++) {
        let measurementAnatomy = [];
        let labelsIn = [];
        let vesselsList = [];
        const measurementElement = measurements[index] as Element;
        
        let anatLocations = this.getChildElements(this.getFirstChildElement(this.getFirstChildElement(measurementElement, "AnatomicalLocation"), "AnatomicalLocationComponents"), "AnatomicalLocationComponent");
        for (let subindex = 0; subindex < anatLocations.length; subindex++) {
          const anatelement = anatLocations[subindex];
          if (AnatomicalLocationLookup[anatelement.getAttribute("GUID") as string])
          {
            AnatomicalLocations.push(AnatomicalLocationLookup[anatelement.getAttribute("GUID") as string] );
            measurementAnatomy.push(AnatomicalLocationLookup[anatelement.getAttribute("GUID") as string]);
          }
          if (LabelsLookup[anatelement.getAttribute("GUID") as string])
          {
            labelsIn.push(LabelsLookup[anatelement.getAttribute("GUID") as string] );
          }
        }
        let vessels = this.getChildElements(this.getFirstChildElement(this.getFirstChildElement(measurementElement, "Graft"), "VesselComponents"), "VesselComponent");
        for (let subindex = 0; subindex < vessels.length; subindex++) {
          const vesselelement = vessels[subindex];
          if (VesselComponentLookup[vesselelement.getAttribute("GUID") as string])
          {
            vesselsList.push(VesselComponentLookup[vesselelement.getAttribute("GUID") as string]);
          }
          if (VesselComponentLookupDetail[vesselelement.getAttribute("GUID") as string])
          {
            labelsIn.push(VesselComponentLookupDetail[vesselelement.getAttribute("GUID") as string] );
          }
          
        }
        let measurementType = MeasurementTypeLookup[(this.getFirstChildElement(measurementElement, "MeasurementType") as Element).getAttribute("GUID") as string];

        let commentsIn = this.getElementValue(measurementElement, "Comments");
        let commentsSetIn = this.getElementValue(measurementSetElement, "Comments");

        let savedTime = this.GetDateFromElement(this.getFirstChildElement(measurementSetElement, "TimeSaved"));

        let measurementId = measurementElement.getAttribute("GUID") as string;

        //Now check for Missing data
        let hasDataFile = otherfiles.filter((item)=> { return item.name.startsWith(measurementId)}).length > 0;
        let newLabelList = [];
        let labelsAdded = [];
        //Remove label duplicates
        for (let index = 0; index < labelsIn.length; index++) {
          const element = labelsIn[index];
          if (labelsAdded.indexOf(element.id) === -1)
          {
            labelsAdded.push(element.id);
            newLabelList.push(element);
          }
        
        }
        
        let previewMeasurement: PreviewMeasurement =
        {
          index: measurementCount,
          id: measurementId,
          comments: commentsIn,
          setComments: commentsSetIn,
          measurementType: measurementType,
          recordDate: savedTime,
          anatomicalLocations: measurementAnatomy,
          labels: newLabelList,
          qualified: measurementQualified === "true" ? true : false,
          missingData: !hasDataFile,
          vessels: vesselsList, 
          measurementSetId: measurementSetIdCurrent,
          measurementSetIndex: measurementSetIndexCurrent
        }
        if (!hasDataFile)
        {
          missingData= true;
        }

        if (measurementType === "Flow")
        {
          flowMeasurementsCount++;
          previewMeasurement.mean = this.GetIndexValue(this.getFirstChildElement(measurementElement, "Indices") as Element, "Mean");
          previewMeasurement.pI = this.GetIndexValue(this.getFirstChildElement(measurementElement, "Indices") as Element, "PI");
          previewMeasurement.dF = this.GetIndexValue(this.getFirstChildElement(measurementElement, "Indices") as Element, "DF");
          previewMeasurement.insuf = this.GetIndexValue(this.getFirstChildElement(measurementElement, "Indices") as Element, "Insuf");
        }else  if (measurementType === "Imaging")
        {
          imagingMeasurementsCount++;
        }
        if (BpmMean > -1)
        previewMeasurement.bPMMean = BpmMean;
        if (BpmMax > -1)
        previewMeasurement.bPMMax = BpmMax;
        if (BpmMin > -1)
        previewMeasurement.bPMMin = BpmMin;
        if (MapMean > -1)
        previewMeasurement.mAPMean = MapMean;
        if (MapMax > -1)
        previewMeasurement.mAPMax = MapMax;
        if (MapMin > -1)
        previewMeasurement.mAPMin = MapMin;

        measurementsList.push(previewMeasurement);
        measurementCount++;
      }

    }
      let procedureOut: PreviewProcedureModel =
      {
        id: caseId as string,
        userId: this.props.dataHelper.user_id,
        patientId: patientidWorking,
        systemId: systemId,
        comments: caseComments ? caseComments : commentsWorking,
        anatomicalLocations: AnatomicalLocations,
        createdDate: startTime,
        flowMeasurements: flowMeasurementsCount,
        imagingMeasurements: imagingMeasurementsCount,
        pWMeasurements: 0,
        reports: 0,
        missingData: missingData,
        selected: false,
        measurements: measurementsList,
        hospitalName: hospitalName,
        index: indexProc,
        weight: weightWorking,
        height: heightWorking,
        gender: genderWorking,
        birthYear: birthYearWorking
        
      };
      return procedureOut;
    }
  }

  processXmlFile = async (fileIn: File, otherFiles: File[]): Promise<PreviewExportModel> =>
  {
    let metadataModel: PreviewExportMetadataModel = {
      id: crypto.randomUUID(), procedures: [],
      studyId:  this.props.studySelected?.id as string
    };

    let previewModel: PreviewExportModel = {
      id: crypto.randomUUID(),
      filename: "",
      serialNumber: "", 
      hospitalName: "",
      department: "",
      softwareVersion: "",
      procedures: [],
      studySelected: "", 
      importDate: new Date(Date.now()),
      importLabel: "",
      confirmEmail: true, 
      includePatientId: false,
      includeDateTime: false,
      includeGenderHeightWeightAge: false,
      encryptionEnabled: false
    };
    let parser = new DOMParser();

    previewModel.filename = fileIn.name;
    let xmlContent = await fileIn.text()
    
     let xmlDoc = parser.parseFromString(xmlContent,"text/xml");
     let ClinicalData = this.getFirstChildElement(xmlDoc.getRootNode() as Element, "ClinicalData") as Element;
     let SystemInfo = this.getFirstChildElement(ClinicalData, "SystemInfo") as Element;
     previewModel.softwareVersion = this.getElementValue(SystemInfo, "SoftwareVersion");

     previewModel.serialNumber = this.getElementValue(SystemInfo, "SystemSerialNumber");//xmlDoc.getElementsByTagName("SystemInfo")[0].getElementsByTagName("SystemSerialNumber")[0].childNodes[0].nodeValue as string;
     previewModel.exportDate = this.ParseDate(this.getElementValue(SystemInfo, "TimeOfExport"));// xmlDoc.getElementsByTagName("SystemInfo")[0].getElementsByTagName("TimeOfExport")[0].childNodes[0].nodeValue as string;
     previewModel.hospitalName = this.getElementValue(SystemInfo, "HospitalName");//xmlDoc.getElementsByTagName("SystemInfo")[0].getElementsByTagName("HospitalName")[0].childNodes[0].nodeValue as string;
    if(this.props.user.userType===userType[3]){
      previewModel.hospitalName = this.props.user.userHospital
    }
     previewModel.department = this.getElementValue(SystemInfo, "Department");//xmlDoc.getElementsByTagName("SystemInfo")[0].getElementsByTagName("Department")[0].childNodes[0].nodeValue as string;
 
    let AnatomicalLocationLookup = this.LookUpNames(ClinicalData, "Component");
    let LabelsLookup = this.LookUpNamesDetail(ClinicalData, "Component");
    let VesselComponentLookup = this.LookUpNames(ClinicalData, "Vessel");
    let VesselComponentLookupDetail = this.LookUpNamesDetail(ClinicalData, "Vessel");
    let MeasurementTypeLookup = this.LookUpNames(ClinicalData, "MeasurementType");
    for (let index = 0; index < Object.keys(MeasurementTypeLookup).length; index++) {
      const element = Object.keys(MeasurementTypeLookup)[index];
      if (MeasurementTypeLookup[element] && MeasurementTypeLookup[element].indexOf("Imaging") > -1)
      {
        MeasurementTypeLookup[element] = "Imaging";
      }
      if (MeasurementTypeLookup[element] && MeasurementTypeLookup[element].indexOf("Transit") > -1)
      {
        MeasurementTypeLookup[element] = "Flow";
      }
    }
    let patients = this.getChildElements(this.getFirstChildElement(ClinicalData, "Patients") as Element, "Patient");
    for (let index = 0; index < patients.length; index++) {
      const element = patients[index];
      let procOut = this.processProcedureNode(element, previewModel.procedures.length,  previewModel.serialNumber, previewModel.hospitalName , AnatomicalLocationLookup, VesselComponentLookup,VesselComponentLookupDetail, MeasurementTypeLookup, LabelsLookup, otherFiles);
      if (procOut)
      {
        previewModel.procedures.push(procOut);
        let procMetadata: PreviewProcedureMetadataModel ={
          id: procOut.id,
          index: previewModel.procedures.length,
          existing: false,
          selected: false,
          processed: 0,
          uploaded: 0,
          measurements: []
        };
        for (let index = 0; index < procOut.measurements.length; index++) {
          let measMetadata: PreviewMetadataMeasurement ={
            id: procOut.measurements[index].id,
            index: index,
            existing: false
          };
          procMetadata.measurements.push(measMetadata);
        }
        metadataModel.procedures.push(procMetadata);
      }

    }

    this.props.dataHelper.CallStudyService("ConfirmStudyData", metadataModel, null, (item: any)=>
    {
      let progModel: PreviewExportMetadataModel = JSON.parse(item);
      let previewMain = this.state.uploadPreview;

      for (let index = 0; index < previewMain.procedures.length; index++) {
        previewMain.procedures[index].existing = progModel.procedures[index].existing ;
        previewMain.procedures[index].selected = !progModel.procedures[index].existing ;
      
          for (let index2 = 0; index2 < previewMain.procedures[index].measurements.length; index2++) {
              previewMain.procedures[index].measurements[index2].existing = progModel.procedures[index].measurements[index2].existing ;
              
                previewMain.procedures[index].measurements[index2].selected = !progModel.procedures[index].measurements[index2].existing ;
          }
      
      }
      this.setState({
        uploadPreview: previewMain
        //progressMetadata: progModel
       });


    }, ()=>{}, "POST");
    return previewModel;
  }

  onUploadConfirmed = async (dataOut: File[]) =>
  {

    for (let index = 0; index < dataOut.length; index++) {
      const element = dataOut[index];
      if (element.name &&  element.name.endsWith(".xml"))
      {
         let previewModel = await this.processXmlFile(element, dataOut);
        
         

         this.setState({
          uploadPreview: previewModel,
          cachedData: dataOut,
          previewReady: true,
      
         });
        
      }
      
    }

    // let uploadData: PreviewExportModel = JSON.parse(dataOut);
    //   this.setState({
    //     previewReady: true,
    //     uploadPreview: uploadData
    //   })
  }


  fileQueue: any = {};
  fileProcedureLookup: any = {};
  failedFiles: any = {};
  totalFiles: number = 0;
  filesUploaded: any = {};
  fileProgress: number = 0;
  totalSize: number = 0;
  currentIndex: number = 0;
  concurrentRequests: number = 0;
  uploadXmlNotStarted: boolean = true;
  maxConcurrentRequests: number = 5;
  labelToUse: string = "";
  mainFormData: any = {};
  callUploadsInterval: any = {};
  finalCallback: Function = ()=>{};
  checkUploads = ()=>{
    let DataRec = this.props.dataHelper;
    while (this.concurrentRequests < this.maxConcurrentRequests && this.uploadXmlNotStarted)
    {
      
      try {
      
      console.log("Concurrent requests: " + this.concurrentRequests);
      console.log("Queue length: " + Object.keys(this.fileQueue).length);
      console.log("Current index: " + this.currentIndex);
      if (this.currentIndex === 1297)
      {
        console.log("Current index: " + this.currentIndex);
      }
      if (this.currentIndex < Object.keys(this.fileQueue).length)
      {

        this.concurrentRequests++;
      let filenameIn = Object.keys(this.fileQueue)[this.currentIndex];
      this.currentIndex++;
      const element = this.fileQueue[filenameIn];
      
      console.log("File size: " +(element as File).size);
      console.log("File code: " +(element as File).name);



        let singleformData = new FormData();
        singleformData.append('files', element);
        singleformData.append('studySelected', this.props.studySelected?.id as string);
        singleformData.append('importLabel', this.labelToUse);

        this.updateProcedureProgress(this.fileProcedureLookup[filenameIn], 1, 0);
      
        DataRec.CallDataServiceFiles("StudyUpload", "SingleFileUpload", singleformData, async (dataOut: any)=>
        {
            this.concurrentRequests--;
            this.filesUploaded[filenameIn] = dataOut;
            for (let index2 = 0; index2 < this.state.uploadPreview.procedures.length; index2++) {
              const procelement = this.state.uploadPreview.procedures[index2];
              
              if (procelement.selected)
              {
                if (!this.state.uploadPreview.includePatientId)
                  procelement.patientId = "";
                else if (this.props.studySelected.encryptionEnabled && this.state.uploadPreview.encryptionEnabled)
                {
                  //Lets try encrypt this here.
                  let enc = new CryptographyHelper();
             
                  let msgOut = await enc.encryptValue(procelement.patientId as string, this.pwd);
                  procelement.patientId = msgOut;
                  
                }
                if (!this.state.uploadPreview.includeGenderHeightWeightAge)
                {
                  procelement.height = 0;
                  procelement.weight = 0;
                  procelement.birthYear = 0;
                  procelement.gender = 0;
                }
                if (!this.state.uploadPreview.includeDateTime)
                  {
                    procelement.createdDate?.setDate(1);
                    procelement.createdDate?.setHours(12);
                    procelement.createdDate?.setMinutes(0);
                    procelement.createdDate?.setSeconds(0);
                  }
                  for (let index3 = 0; index3 < procelement.measurements.length; index3++) {
                    const measurementelement = procelement.measurements[index3];
                    if (filenameIn.indexOf(measurementelement.id) === 0 && measurementelement.selected)
                      measurementelement.fileUrl = dataOut;

                      if (!this.state.uploadPreview.includeDateTime)
                      {
                        measurementelement.recordDate?.setDate(1);
                        measurementelement.recordDate?.setHours(12);
                        measurementelement.recordDate?.setMinutes(0);
                        measurementelement.recordDate?.setSeconds(0);
                      }

                  }
              }
            }
            this.updateProcedureProgress(this.fileProcedureLookup[filenameIn], 2, 0);

            this.fileProgress = this.fileProgress + element.size;
            this.setState({
              uploadStatus: "Uploading",
              progressText: "",
              initialUploadStatus: this.fileProgress === this.totalSize ? "Initial Upload Complete" : "File upload progress: " + ( this.fileProgress/1000000).toFixed(2)  +" MB/"+ (this.totalSize/1000000).toFixed(2)  +" MB",
              fileProgress: Object.keys(this.filesUploaded).length,
              totalFiles: this.totalFiles
            });
           
            //this.uploadXmlFile(DataRec);
            // console.log("Files Uploaded successfully");
            // callback(dataOut);
            //this.props.onUploadComplete(dataOut);
        }, (status: number, errorText: string, statusText: string) =>{ 
          //If a file fails on upload lets give up straight away and expect 1 less file?
         
          this.totalFiles--; 

          this.failedFiles[element.name.toLowerCase()] = element;
          this.concurrentRequests--;
          if (this.errorWindow.current  as ErrorWindow)
            (this.errorWindow.current  as ErrorWindow).show(status, "An upload of a file failed. This can be closed and the upload will continue... \n" + errorText, statusText, "File upload failed")
        }, this.progressUpdatedOther, false);
    }else{
      if (this.concurrentRequests === 0)
      {
        if (this.uploadXmlNotStarted)
        {
          if (this.uploadXmlFile(DataRec))
          {
            clearInterval(this.callUploadsInterval);
          }
      }
      }else{
        this.concurrentRequests--;
      }
    }
      
  } catch (error) {
    this.concurrentRequests--;
  }
  }
  }


  uploadXmlFile = (DataRec: DataHelper) =>
  {
    this.uploadXmlNotStarted = false;
    // if (Object.keys(this.filesUploaded).length === this.totalFiles)
    //   {
        if (Object.keys(this.failedFiles).length > 0)
        {
          //If there are failed files we could try reupload them here?
          if (this.infoBox.current  as InfoBox)
          (this.infoBox.current  as InfoBox).show(Object.keys(this.failedFiles).length + " files failed during upload. These can be uploaded later. "  );
          console.log("Upload failed files: " + JSON.stringify(Object.keys(this.failedFiles)));
        }
       

        //Once all files are done we can set to 0 so we don't try upload again.
        // this.totalFiles = -1;

        // clearInterval(this.callUploadsInterval);
        //Now we are ready to do the final upload
        //This is a little weird. Still torn to as to whether we should use the hear instead?
          let uploadPreview = this.state.uploadPreview;
          uploadPreview.studySelected = this.props.studySelected.id as string;
          uploadPreview.importLabel = this.labelToUse;
          this.mainFormData.append("previewexport", JSON.stringify(uploadPreview));
         
        DataRec.CallDataServiceFiles("StudyUpload", "FileUpload", this.mainFormData, (dataOut: any, )=>
        {
            
            console.log("Files Uploaded successfully");
            this.finalCallback(dataOut);
            //this.props.onUploadComplete(dataOut);
        }, (status: number, errorText: string, statusText: string) =>{ 
          if (status === 500)
          {
            //A 500 error indicates that something went wrong where as anything else may still succeeed so donæt show an error just log it.
            if (this.errorWindow.current  as ErrorWindow)
              (this.errorWindow.current  as ErrorWindow).show(status, errorText, statusText, "File upload failed")
          }else
          {
            console.log("FileUpload failed: " + status +" - " + errorText + " - " + statusText );
          }
        }, this.progressUpdated, false);

        return true;
      // }
      // return false;

  }

  uploadFiles = async (e: any, callback: Function) =>
      {
        this.uploadXmlNotStarted = true;
        this.totalFiles = 0;
        this.totalSize = 0;
        this.fileProgress = 0;
        this.fileQueue = {};
        this.fileProcedureLookup = {};
        this.filesUploaded = {};
        this.setState({
          uploadStatus: "Preparing",
          progressText: "",
          initialUploadStatus: "Preparing raw data files...",
          totalFiles: 1
        });
        let labelToUse = "OTHER";
        if (this.state.uploadPreview.importLabel)
        {
          labelToUse = this.state.uploadPreview.importLabel;
        }
        let formData = new FormData();

        // let pwd = this.props.dataHelper.getPwd(true);
        // for (let index2 = 0; index2 < this.state.uploadPreview.procedures.length; index2++) {
        //   const procelement = this.state.uploadPreview.procedures[index2];
          
        //   if (procelement.selected)
        //   {
        //     if (!this.state.uploadPreview.includePatientId){
        //     procelement.patientId = "";
        //     }
            
        //     else if (this.props.studySelected.encryptionEnabled)
        //     {
        //       //Lets try encrypt this here.
        //       let enc = new CryptographyHelper();
           
        //       let msgOut = await enc.encryptValue(procelement.patientId as string, pwd);
        //       procelement.patientId = msgOut;
              
        //     }
        //   }
        // }

        if (this.props.studySelected.encryptionEnabled && this.state.uploadPreview.encryptionEnabled)
        {
          this.pwd = this.props.dataHelper.getPwd(true);
        }
       
              for (let index = 0; index < this.state.cachedData.length; index++) {
                  const element = this.state.cachedData[index];
                  let isSelected = element.name.toLowerCase().endsWith(".xml");
                  let procId = "";
                  for (let index2 = 0; index2 < this.state.uploadPreview.procedures.length; index2++) {
                    const procelement = this.state.uploadPreview.procedures[index2];
                      procId = procelement.id;

                        for (let index3 = 0; index3 < procelement.measurements.length; index3++) {
                          const measurementelement = procelement.measurements[index3];
                          if (element.name.indexOf(measurementelement.id) === 0 && measurementelement.selected)
                            isSelected = true;
                        }
                    
                      }
                  if (isSelected && !element.name.toLowerCase().endsWith(".xml"))
                  {
                    this.totalFiles++;
                    this.fileQueue[element.name.toLowerCase()] = element;
                    this.fileProcedureLookup[element.name.toLowerCase()] = procId;
                    this.totalSize += element.size;
                  
                  }else if (element.name.toLowerCase().endsWith(".xml"))
                  {
                    //TODO: We no longer upload the XML file to avoid privacy issue.
                    //formData.append('files', element);
                  }
                   
              }
              this.setState({
                uploadStatus: "Uploading",
                progressText: "",
                initialUploadStatus: "Initializing upload...",
                totalFiles: 1
              });

              this.currentIndex = 0;
              this.labelToUse =labelToUse;
              this.mainFormData = formData;
              this.finalCallback = callback;
              this.callUploadsInterval = setInterval(this.checkUploads, 1000);

              // for (let index = 0; index < Object.keys(this.fileQueue).length; index++) {
                
               
               
              // } 
      }

      updateProcedureProgress = (procId: string, uploaded: number, processed: number) : void =>
        {
          let uploadProcs = this.state.progressMetadata;
          let ischanged = false;
            for (let index = 0; index < uploadProcs.procedures.length; index++) {
            if (procId=== uploadProcs.procedures[index].id)
              {
                if (uploadProcs.procedures[index].processed  !== processed)
                  {
                    ischanged = true;
                    uploadProcs.procedures[index].processed = processed;
                  }
           //Uploading moves up. (It's updated per measurement.)
                if (uploadProcs.procedures[index].uploaded  < uploaded)
                  {
                    ischanged = true;
                    uploadProcs.procedures[index].uploaded = uploaded;
                  }
               
              }
            }
      if ( ischanged)
        {
          this.setState({
            progressMetadata: uploadProcs
          });
        //  this.forceUpdate();
        }
           
        }

      pingProgress = (clearTimerFunction: Function) : void =>
      {
          this.props.dataHelper.CallService("StudyUpload", "PingProgress", {}, { instructionId: this.props.dataHelper.user_id}, (item: string)=>{
          let progModel: ProgressModel = JSON.parse(item);

          let uploadProcs = this.state.progressMetadata;
          
            if (progModel.progressMetadata)
            {
            for (let index1 = 0; index1 < progModel.progressMetadata.procedures.length; index1++) {

              
              for (let index = 0; index < uploadProcs.procedures.length; index++) {
              if (progModel.progressMetadata.procedures[index1].id === uploadProcs.procedures[index].id)
                {
                  uploadProcs.procedures[index].processed = progModel.progressMetadata.procedures[index1].processed;
                  uploadProcs.procedures[index].uploaded = progModel.progressMetadata.procedures[index1].uploaded;
                }
              }
            }
          }
          this.setState({
            uploadStatus: "",
            progressText: "Processing completed." , 
            progressMetadata: uploadProcs
          });

            this.progressUpdated({
              loaded: progModel.current, total:  progModel.total
            } as any, "Process");
            clearTimerFunction(progModel.current !== progModel.total);
          }, ()=>
          {

          }, "GET", false);
      }

      renderKeyValueInGrid = (item: any) =>
      {
          if (item as number)
          {
            return <div>{(item as number).toFixed(2)}</div>
          }else
          {
              return <div>--</div>
          }
      }

      //Put these in a helper class somewhere common to avoid redefinition.
      renderMeanValueInGrid = (item: any) =>
      {
          if (item as number)
          {
            return <div>{(item as number * 1000).toFixed(2)}</div>
          }else
          {
              return <div>--</div>
          }
      }
      renderListInGrid = (items: string[]) =>
      {
        let valToShow = "";
          for (let index = 0; index < items.length; index++) {
            const element = items[index];
            valToShow += element;
            if  (index < items.length-1)
              valToShow +=" - ";
          }
          return <div className="data-grid-cell">{valToShow}</div>;
      }

      getSelectedProcedures = (exportModel: PreviewExportModel): PreviewProcedureModel[] => 
      {
        return exportModel.procedures.filter((procedure) => procedure.selected === true);
      }

      getSelectedMeasurements= (procedureModel: PreviewProcedureModel): PreviewMeasurement[] => 
      {
        return procedureModel.measurements.filter((measurement) => measurement.selected === true);
      }

      createRow(name: string, caption: string, renderTemplate: string) {
        return {
          Name: name,
          Caption: caption,
          RenderTemplate: renderTemplate
        };
      }
     

  render() {

    


    return (
        <>
      <MultiFileBrowser ref={this.fileBrowserMulti} performUpload={false} controllerName="StudyUpload" methodName={"FileUpload"} dataTransfer={this.props.dataHelper} progressEvent={this.progressUpdated} onUploadComplete={this.onUploadConfirmed} onUploadFailed={(status: number, errorText: string, statusText: string) =>{ 
              if (this.errorWindow.current  as ErrorWindow)
                (this.errorWindow.current  as ErrorWindow).show(status, errorText, statusText, "File upload failed")
            }}></MultiFileBrowser>
        {(this.state.status ==="uploadReady") ?       
        <><div id="uploadDataHere" className="uploadData">Upload data here</div>
        <div>{this.state.progressText}{this.state.progressTextDetails ? <span className="error-tooltip">{this.state.progressTextDetails}</span> : null}</div></>
        :<></>}   
        
        
          {(this.state.previewReady && (this.state.status==="uploadClicked" || this.state.status === "confirmClicked"))
          ?
          <div className="caseUploadWrapper">
            <div className="caseUploadForm">
              <div className="uploadBox uploadFormLeft">
                    <InputItem type="text" title={"Hospital Name:"} name={"hospitalName"} onChange={(name: string, value: string)=>
                    {if (this.props.user.userType!==userType[3]){
                      let copyOfUploadPreview = {...this.state.uploadPreview};
                      copyOfUploadPreview.hospitalName = value
                      this.setState({
                          uploadPreview: copyOfUploadPreview
                        });
                    }}} value={this.state.uploadPreview.hospitalName}></InputItem>
                <label><span>Department:</span> <em>{this.state.uploadPreview.department}</em></label>
                <label><span>Serial Number:</span> <em>{this.state.uploadPreview.serialNumber}</em></label>
                <label><span>Exported:</span> <em>{this.state.uploadPreview.exportDate?.toLocaleString()}</em></label>
                <label><span>Total procedures:</span> <em>{this.state.uploadPreview.procedures.length}</em></label>
                
              </div>

              <div className="uploadBox uploadFormCenter">
                  {/* <label>
                      <span>Import label:</span>
                      <input type="text" value={this.state.uploadPreview.importLabel} onChange={(e)=>
                  {
                    let copyOfUploadPreview = {...this.state.uploadPreview};
                    copyOfUploadPreview.importLabel = e.currentTarget.value
                    this.setState({
                        uploadPreview: copyOfUploadPreview
                      });
                  }}/>
                  </label> */}
 {
                      !this.props.studySelected.allowPersonalData || this.state.status==="confirmClicked"
                      ?
                      <></>
                      :
                  <label>
                    <span>Include patient id</span>
                      <input type="checkbox" checked={this.state.uploadPreview.includePatientId} onChange={(e)=>
                    {
                      let copyOfUploadPreview = {...this.state.uploadPreview};
                      
                      copyOfUploadPreview.includePatientId = e.currentTarget.checked;
                      if (copyOfUploadPreview.includePatientId)
                        copyOfUploadPreview.encryptionEnabled = true;
                      this.setState({
                          uploadPreview: copyOfUploadPreview
                        });
                    }}
                 
                    />
                  </label>
                }
                  {this.props.studySelected.encryptionEnabled && this.state.uploadPreview.includePatientId &&(
                    <label>
                      <span>Use personal pin to encrypt data</span>
                        <input type="checkbox" checked={this.state.uploadPreview.encryptionEnabled} onChange={(e)=>
                    {
                      let copyOfUploadPreview = {...this.state.uploadPreview};
                      copyOfUploadPreview.encryptionEnabled = e.currentTarget.checked;
                      this.setState({
                          uploadPreview: copyOfUploadPreview
                        });
                    }}/>
                    </label>
                  )}
                    {
                      !this.props.studySelected.allowPersonalData || this.state.status==="confirmClicked"
                      ?
                      <></>
                      :
                  <label>
                    <span>Include full date</span>
                      <input type="checkbox" checked={this.state.uploadPreview.includeDateTime} onChange={(e)=>
                    {
                      let copyOfUploadPreview = {...this.state.uploadPreview};
                      copyOfUploadPreview.includeDateTime = e.currentTarget.checked
                      this.setState({
                          uploadPreview: copyOfUploadPreview
                        });
                    }}
                  
                    />
                  </label>
                    }
                    {
                      !this.props.studySelected.allowPersonalData || this.state.status==="confirmClicked"
                      ?
                      <></>
                      :
                      <label>
                    <span>Include height, weight, gender and year of birth</span>
                      <input type="checkbox" checked={this.state.uploadPreview.includeGenderHeightWeightAge} onChange={(e)=>
                    {
                      let copyOfUploadPreview = {...this.state.uploadPreview};
                      copyOfUploadPreview.includeGenderHeightWeightAge = e.currentTarget.checked
                      this.setState({
                          uploadPreview: copyOfUploadPreview
                        });
                    }}
                    />
                  </label>
                    }
                  
                  <label>
                    <span>Send confirmation email after upload is available</span>
                      <input type="checkbox" checked={this.state.uploadPreview.confirmEmail} onChange={(e)=>
                    {
                      let copyOfUploadPreview = {...this.state.uploadPreview};
                      copyOfUploadPreview.confirmEmail = e.currentTarget.checked
                      this.setState({
                          uploadPreview: copyOfUploadPreview
                        });
                    }} disabled={this.state.status==="confirmClicked"}/>
                  </label>

              </div>
              <div className="uploadBox uploadFormRight">
              {(this.state.uploadStatus ==="Uploading" || this.state.uploadStatus ==="Preparing") ?   
               <div className="innerBox doNotClose">
                <h3>Do not close this browser window until the files upload and data processing are finished.</h3>
              </div>
          :
          (this.state.uploadStatus ==="Processing") ?  
          
            ((this.state.status==="confirmClicked" &&  this.state.uploadedData[0] > 0 )? 
            <div className="innerBox uploadCompleted">
              <div className="gg-check"></div>
              <div className="textCompleted"> Successfully uploaded  {this.state.uploadedData[0]} procedures and {this.state.uploadedData[1]} measurements.</div>
            </div> :
          <div className="innerBox canClose">
          <div>You can now close the window </div> 
     </div>  
    )
          
          
          :
          <div className="innerBox normalBox">
                   <h3>Instructions </h3> 
                   <p>Select procedures and measurements to upload in the table below.</p>
                   <p>Click UPLOAD when ready.</p> 
              </div>
        }

      
              </div>
          </div>

          <div id="proceduresGrid" className="proceduresGrid">
            <h2>Procedures</h2>
            {this.state.status==="confirmClicked" ?   
             <DataGrid columns={[ {Name: "selected", Caption: "Selected", SummaryTemplate: ()=>{ return <></>},
             RenderTemplate: (value: boolean, rowItem, parentRow) => {
   
               return <div className="primaryColumnChild">
                 <div className="caseNumberUpload">
                   {rowItem.index +1}
                 </div>

                 {
                   rowItem.existing ?
                   <div className="columnChildNote">Previously uploaded</div>
                   :<></>
                 }
                 </div>

                 }
             },
             {Name: "uploaded", Caption: "Uploaded", Type: "uploadstatus"},
             {Name: "processed", Caption: "Processed", Type: "uploadstatus"},
             {Name: "status", Caption: ""},
             ]} rows={this.state.progressMetadata.procedures}></DataGrid>
            :
            <DataGrid studySelected={this.props.studySelected} onChange={(rowdata: DataGridRow, columnChanged:string, value: any, index: number | undefined, submitToServer: boolean| undefined) =>{
              if (this.state.uploadPreview && this.state.uploadPreview.procedures && index as number >= 0 && index as number < this.state.uploadPreview.procedures.length)
              { 
                //eslint-disable-next-line  
                this.state.uploadPreview.procedures[index as number].selected = value as boolean; 
                  
                  for (let lc = 0; lc < this.state.uploadPreview.procedures[index as number].measurements.length; lc++) {
                    const element = this.state.uploadPreview.procedures[index as number].measurements[lc];
                    //eslint-disable-next-line
                    element.selected = value as boolean;
                  
                }
              }
                this.forceUpdate();
            }} 
            onChildChange={(rowdata: DataGridRow,parentrowdata: DataGridRow, columnChanged:string, value: any, index: number | undefined, parentIndex: number | undefined, submitToServer: boolean| undefined) =>{
              
              if (this.state.uploadPreview && this.state.uploadPreview.procedures && parentIndex as number >= 0 && parentIndex as number < this.state.uploadPreview.procedures.length)
              {
                if (this.state.uploadPreview && this.state.uploadPreview.procedures && parentIndex as number >= 0 && parentIndex as number < this.state.uploadPreview.procedures.length){
                  if ((parentrowdata as PreviewProcedureModel).selected === false && (value as boolean)=== true) {
                      //this should disable the checkboxes of measurements when you uncheck a procedure
                  }
                  else{
                    // eslint-disable-next-line
                    this.state.uploadPreview.procedures[parentIndex as number].measurements[index as number].selected = value as boolean; 

                  }  
                }
                
                
              }
                  
                this.forceUpdate();
            }}
            
            columns={[
                  {Name: "selected", Caption: "Selected", Type:"boolean", Editable: true,
                  RenderTemplate: (value: boolean, rowItem, parentRow) => {
                    let type = "checkbox";
        
                    return <div className="primaryColumnChild">
                      
                      <label><input  type={type} checked={value}
                        onChange={(e)=>
                          {
                           
                            rowItem["selected"] = (e.target as HTMLInputElement).checked;
                            let valueIn = rowItem["selected"];
                                if (this.state.uploadPreview && this.state.uploadPreview.procedures && rowItem.index  as number >= 0 && rowItem.index as number < this.state.uploadPreview.procedures.length)
                                { 
                                  //eslint-disable-next-line  
                                  this.state.uploadPreview.procedures[rowItem.index as number].selected = valueIn as boolean; 
                                    
                                    for (let lc = 0; lc < this.state.uploadPreview.procedures[rowItem.index as number].measurements.length; lc++) {
                                      const element = this.state.uploadPreview.procedures[rowItem.index as number].measurements[lc];
                                      //eslint-disable-next-line
                                      element.selected = valueIn as boolean;
                                    
                                  }
                                }
                                  this.forceUpdate();
                              
                            
                          }
                        }
                      /></label>

                      <div className="caseNumberUpload">
                        {rowItem.index +1}
                      </div>    

                      {
                        rowItem.existing ?
                        <div className="columnChildNote">Previously uploaded</div>
                        :<></>
                      }
                      </div>

                      }
                  }, 
                  {Name: "patientId", Caption: "Patient Id", additionalChildClass: this.state.uploadPreview.includePatientId ? "" : "unusedColumnChild"}, 
                  {Name: "anatomicalLocations", Caption: "Anatomical Locations", RenderTemplate:  this.renderListInGrid },
                  {Name: "createdDate", Caption:  "Date", Type: this.state.uploadPreview.includeDateTime ? "date" : "datenoday", additionalChildClass: this.state.uploadPreview.includeDateTime ? "" : "unusedColumnChild"}, 
                  {Name: "height", Caption: "Height", Type: "int", hidden: !this.state.uploadPreview.includeGenderHeightWeightAge}, 
                  {Name: "weight", Caption: "Weight", Type: "int", hidden: !this.state.uploadPreview.includeGenderHeightWeightAge}, 
                  {Name: "gender", Caption: "Gender", hidden: !this.state.uploadPreview.includeGenderHeightWeightAge}, 
                  {Name: "age", Caption: "Age", Type: "int", hidden: !this.state.uploadPreview.includeGenderHeightWeightAge}, 
                  {Name: "flowMeasurements", Caption: "Flow"},
                  {Name: "imagingMeasurements", Caption: "Imaging"},
                  {Name: "reports", Caption: "Reports"},
                  {Name: "comments", Caption: "Comments"},
          
                  {Name: "measurements", Caption: "Thumbnails", RenderTemplate: (item: PreviewMeasurement[], rowItem, parentRow) => {
                    let itemsOut = [];
                    let index = 0;
                    while (itemsOut.length < 4 && index < item.length)
                    { 
                      const element = item[index];
                      itemsOut.push(<MeasurmentThumbnail isExpandable={false} studySelected={this.props.studySelected} procedureId={rowItem.id} dataHelper={this.props.dataHelper} rowData={element as PreviewMeasurement} detailed={false} previewAvailable={false}  ></MeasurmentThumbnail>);
                      index++;
                    }
                    return <div className="thumbnails-container">{itemsOut} <span className="accordionArrow">&#9654;</span></div>;
                  }}
                  ]} rows={this.state.uploadPreview.procedures} hasHierarchy={true} childrenpropertyname="measurements" childcolumns={[
                    {Name: "selected", Caption: "Selected", Type:"boolean", Editable: true, }, 
                    {Name: "index", Caption: "Name"}, 
                    {Name: "measurementType", Caption: "Type"}, 
                    {Name: "recordDate", Caption: "Date", Type: "date"}, 
                    {Name: "mean", Caption: "MF", RenderTemplate: this.renderMeanValueInGrid},
                    {Name: "pI", Caption: "PI", RenderTemplate: this.renderKeyValueInGrid},
                    {Name: "dF", Caption: "DF", RenderTemplate: this.renderKeyValueInGrid},
                    {Name: "anatomicalLocations", Caption: "Anatomical Locations", RenderTemplate:  this.renderListInGrid},
                  
                    {Name: "measurementType", Caption: "Thumbnail", RenderTemplate: (item: string) => {
                      let itemsOut =[];
                        if (item === "Flow")
                        {
                          itemsOut.push(<div className="imaging-thumbnail"><img alt="Flow" src="flowimage.png"/></div>)
                        }
                        else if (item === "Imaging")
                        {
                          itemsOut.push(<div className="imaging-thumbnail"><img alt="Imaging" src="/usimage.png"/></div>)
                        }
                      
                      return <div className="thumbnails-container">{itemsOut}</div>;
                    }},
                    {Name: "qualified", Caption: "Qualified", Type: "boolean"}
                    ]}  getChildGridRowAlternateColor={
                      (item:DataGridRow) => { 
                        return ((item as PreviewMeasurement)?.measurementSetIndex as number) % 2 === 0 }
                      }></DataGrid>
                    }
            </div>
           
          {/* <div>{
            this.state.uploadPreview.procedures.map((item: PreviewProcedureModel, index: number)=>
            {
                return <div>
                  <div>Procedure {index}</div>
                  <div >{item.createdDate}</div>
                  <div>{item.comments}</div>
                  <div>{item.anatomicalLocations}</div>
                  <div>{item.flowMeasurements}</div>
                  <div>{item.imagingMeasurements}</div>
                  <div></div>
                </div>
            })
          } </div> */}
          </div>
          : <></>
        }

        <div className="caseUploadFooter">
          
        {(this.state.status==="uploadReady"||this.state.status==="uploadClicked")?
        <button className="primaryButton" onClick={()=>
          {
              (this.fileBrowserMulti.current as MultiFileBrowser).openDialog();
              this.setState({status:"uploadClicked"});
  
          }}>Select files to upload</button>: <></>}

          {
            (this.state.status==="confirmClicked" &&  this.state.uploadedData[0] > 0 )?  <button className="primaryButton actionButton" onClick={()=>
              {
                this.props.closeClicked();
      
              }}>Done</button> : <></>
          }
        
        {
             (true || (this.state.previewReady && this.state.uploadPreview && this.state.uploadPreview.procedures && this.state.uploadPreview.procedures.filter((item)=> { return item.selected === true}).length > 0)) && this.state.status===("uploadClicked")
            ?
            <button className={"primaryButton actionButton "} disabled={(this.state.previewReady && this.state.uploadPreview && this.state.uploadPreview.procedures && this.state.uploadPreview.procedures.filter((item)=> { return item.selected === true}).length > 0) ? false : true} onClick={()=>
              {
                let exportModel: PreviewExportModel;
                let selectedProcedureModels: PreviewProcedureModel[];
                let selectedMeasurementModels: PreviewMeasurement[];
                let progressMetadataIn: PreviewExportMetadataModel = {
                  studyId:"",
                  id: "",
                  procedures: []
                };

                for (let index = 0; index < this.state.uploadPreview.procedures.length; index++) {
                  const element = this.state.uploadPreview.procedures[index];
                  if (element.selected)
                  {
                      let procItem: PreviewProcedureMetadataModel ={
                        id: element.id,
                        index: element.index,
                        uploaded: 0,
                        processed: 0,
                        existing: false,
                        selected: element.selected,
                        measurements: []
                    };
                    progressMetadataIn.procedures.push(procItem)
                  }
                  
                }

                this.setState({status:"confirmClicked", progressMetadata: progressMetadataIn})



                this.uploadFiles("", (dataOut: string)=>{
                  exportModel = JSON.parse(dataOut);

                  selectedProcedureModels = this.getSelectedProcedures(exportModel);

                  selectedMeasurementModels = [];
                  let uploadProcs = this.state.progressMetadata;
                  selectedProcedureModels.forEach(item => { 

                    for (let index = 0; index < uploadProcs.procedures.length; index++) {
                      if (item.id === uploadProcs.procedures[index].id)
                        {
                          uploadProcs.procedures[index].processed =1;
                          uploadProcs.procedures[index].uploaded =3;
                        }
                    }

                    this.getSelectedMeasurements(item).forEach(selected => selectedMeasurementModels.push(selected));
                 
                 
                   

                  });
                  this.pwd = "";
                  //The upload is completed now.
                  this.props.refreshStudyRequested();
                  this.setState({uploadedData: [selectedProcedureModels.length,selectedMeasurementModels.length], progressMetadata: uploadProcs});
                  
                });
                ;
              
              
              }}>UPLOAD</button>
              
              : <></>
        } 
       </div>
        <InfoBox ref={this.infoBox} title="Confirmation" type="confirmation"></InfoBox> 
        <ErrorWindow ref={this.errorWindow}></ErrorWindow>

        <div className="uploadProgressbar">
        {/* {(this.state.uploadStatus ==="Uploading" || this.state.uploadStatus ==="Preparing") ?   
          <h4 className="doNotClose">Do not close this browser window until the files upload and data processing are finished.</h4>
          :
        <></>
        } */}
        {((this.state.initialUploadStatus && this.state.status==="confirmClicked" && this.state.fileProgress < this.state.totalFiles) ? <><div><Progressbar value={this.state.fileProgress} max={this.state.totalFiles} title={this.state.initialUploadStatus} showValues={false}></Progressbar></div></>: <></>)} 
          {/* {((this.state.progressText && this.state.status==="confirmClicked") ? <><div><Progressbar value={this.state.uploadLoaded} max={this.state.uploadTotal} title={""} showValues={false}></Progressbar></div></>: <></>)}  */}
        </div> 
        
      
        
      </>
    );
  }
}
