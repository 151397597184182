import React from "react";
import { DataHelper } from "../Helpers/DataHelper";
import { PreviewMeasurement } from "../Models/PreviewMeasurement";
import ChartD3 from "./ChartD3";
import { StudyModel } from "../Models/StudyModel";
import { LoadingWidget } from "./LoadingWidget";

export class MiniTTFMCurve extends React.Component<
    {
        subType: string,
        studySelected: StudyModel,
        dataReceiver: DataHelper, 
        measurementPreview: PreviewMeasurement,
        procedureid: string,
        width: number, 
        height: number,
        usefilling: boolean
        color: string, 
        color1: string, 
        color2: string, 
        color3: string, 
        includeScrubber: boolean,
        yAxisVisible: boolean,
        xAxisVisible: boolean,
        parentGroup: string
    },
    {
        axisSize: number,
        cursorPosition: number, 
        dataLoaded: boolean,
        errorMessage: string
    }
> {

    ;
    currentData: any = null;
    holderDiv: React.RefObject<HTMLDivElement>;
    ttfmChartd3: React.RefObject<ChartD3>;
    constructor(props: any) {
        super(props);
        this.holderDiv = React.createRef();
        this.ttfmChartd3 = React.createRef();
        this.state = {
            axisSize: 500,
            cursorPosition: 500,
            dataLoaded: false,
            errorMessage: ""
        }
    }

    addDataPoints = (dataToRenderAll: any, compareMode = false, scrubberPos = 0) =>
    {
        let colorPalette = [this.props.color1, this.props.color3, "var(--Raven)", "var(--GreenApple)", "var(--Crocodile)", "var(--BananaClan)", "var(--Bluejay)", "var(--FireEngineRed)"]
        let MainKey = "BLUE";
        let reservedKeys = ["Phase", "PhaseFull", "ECG", "PI", "ACI", "x"];
        let ttfmChartd3: ChartD3 = (this.ttfmChartd3.current as ChartD3);
      if (!ttfmChartd3)
        return;
   
      let xAxisSize = 1000;
      let yAxisMin = 0;
      let yAxisMax = 0;

      if (dataToRenderAll)
      {

          let reservedKeys = ["Phase", "PhaseFull", "ECG", "PI", "ACI", "x"];

          for (let dataProp in dataToRenderAll) {
              if (reservedKeys.indexOf(dataProp) === -1)
              {
              const dataValues = dataToRenderAll[dataProp];
              if (dataValues && dataValues.length > 0) {
                  for (let index = 0; index < dataValues.length; index++) {
                      const element = dataValues[index];
                      if (yAxisMin > element)
                      {
                          yAxisMin = element;
                      }
                      if (yAxisMax < element)
                      {
                          yAxisMax = element;
                      }
                      
                  }
              }
              }

          }
      }

      ttfmChartd3.resetDrawingArea(null, null, yAxisMin, yAxisMax, xAxisSize, 0, 0 );
      let seriesIndex = 0;
      for (let dataProp in dataToRenderAll) {
          if (reservedKeys.indexOf(dataProp) === -1)
          {
          const dataValues = dataToRenderAll[dataProp];
          if (dataValues && dataValues.length > 0) {
              if (dataProp === MainKey)
              {
            if (this.props.usefilling){ //&& dataToRenderAll["Phase"] &&dataToRenderAll["Phase"].length > 0) {

             
            
                if (dataToRenderAll["PhaseFull"] && dataToRenderAll["PhaseFull"].length > 0 )
                {
                    //First draw a filled line with no change in phase and mark the items added with their x value.
                ttfmChartd3.addAreaData(dataToRenderAll[dataProp], this.props.color1, "TTFM", dataToRenderAll["x"], compareMode, scrubberPos);
                ttfmChartd3.setAreaColor(dataToRenderAll["PhaseFull"], this.props.color1, this.props.color2);
                }
else if (dataToRenderAll["Phase"].length > 0 )
{

                var phaseMarker = -1;
                var lastDrawn = 0;
                for (let index = 0; index < dataToRenderAll["Phase"].length; index++) {
                    const element = dataToRenderAll["Phase"][index];
                    if (element !== phaseMarker) {
                        if (phaseMarker === -1) {
                            phaseMarker = element;
                        } else {
                            ttfmChartd3.addAreaData(dataToRenderAll[dataProp].slice(lastDrawn, index), ((phaseMarker === 1)
                                ? this.props.color1
                                : this.props.color2), "TTFM", dataToRenderAll["x"].slice(lastDrawn, index), compareMode, scrubberPos);
                            phaseMarker = element;
                          //  datapointsaddedblue = datapointsaddedblue - lastDrawn + index;
                            lastDrawn = index;
                        }
                    }
    
                }
                if (lastDrawn < dataToRenderAll[dataProp].length) {
                    //If it wasn't drawn all the way to the end the draw the last bit.
                    ttfmChartd3.addAreaData(dataToRenderAll[dataProp].slice(lastDrawn, dataToRenderAll[dataProp].length), ((phaseMarker === 1)
                        ? this.props.color1
                        : this.props.color2), "TTFM", dataToRenderAll["x"].slice(lastDrawn, dataToRenderAll[dataProp].length), compareMode, scrubberPos);
                   // datapointsaddedblue = datapointsaddedblue - lastDrawn + dataToRenderBlue.length;
                }

            }else
            {
                ttfmChartd3.addAreaData(dataToRenderAll[dataProp], this.props.color1, "TTFM", dataToRenderAll["x"], compareMode, scrubberPos);
            }
                
            }else
            {
                //Override the first color to something darker when there is no filling.
                ttfmChartd3.addLineData(dataToRenderAll[dataProp], this.props.color1 === "var(--WaterySea)" ? "var(--TTFMPurple2)" : this.props.color1, "TTFM", dataToRenderAll["x"], compareMode, scrubberPos);
            }
        }else{

            let colorToUse = colorPalette[seriesIndex];
            if (dataProp === "RED")
                colorToUse = colorPalette[1];
            ttfmChartd3.addLineData(dataToRenderAll[dataProp],colorToUse, dataProp, dataToRenderAll["x"], compareMode, scrubberPos);

        }

          }
          seriesIndex++;
        }
        
   
      }
    }

    adjustScrubber = (value: number) =>
    {
       //this.state.cursorPosition = value;
       this.setState({ cursorPosition: value}, ()=> {this.addDataPoints(this.currentData, true, value); })
        
    }
    
    formatXAxisLabels = (value: number) =>
    {
        return "";
        //return value/200 + "s";
    }

     static defaultProps ={
        subType: "Flow",
        width:180,
        height: 120,
        usefilling: true,
        includeScrubber: false,
        color: "black",
        color1: "var(--SassySalmon)",
        color2: "var(--WaterySea)",
        color3: "var(--Lacustral)",
        yAxisVisible: true,
        xAxisVisible: true,
        parentGroup: ""
     }

     onVisible = (element: Element, callback: (e:Element) => void) => {
        new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            if(entry.intersectionRatio > 0) {
              callback(element);
              observer.disconnect();
            }
          });
        }).observe(element);
    }
    
    refreshData = ()=>
    {
        if (this.props.measurementPreview.id && !this.currentData)
        {
            // this.setState({
            //     dataLoaded: false
            // });
            this.props.dataReceiver.CallService("MiscData", "GetThumbFlowData", {}, { studyId: this.props.studySelected.id, measurementId: this.props.measurementPreview.id, measurementType: this.props.subType, procedureid: this.props.procedureid, tokenId: this.props.dataReceiver.miscToken},  (measurementDataRaw: string)=>{
            this.currentData = {};

            if (measurementDataRaw)
            {
            this.currentData = JSON.parse(measurementDataRaw);
     
            this.addDataPoints( this.currentData, true, this.currentData["x"].length - 1015);
            this.setState({
                axisSize: this.currentData["x"].length,
                cursorPosition: this.currentData["x"].length - 1015      ,
                dataLoaded: true  
             });
            }else
            {
                this.setState({
                    errorMessage: "No flow measurement data found.",
                    dataLoaded: true
                 });
            }
        }, (errorVal: any) =>
        {
            this.setState({
                errorMessage: "Unable to load measurement",
                dataLoaded: true
             });
            console.log("Error loading thumbnail data.")
        }, "GET", false);
        }
    }


    componentDidMount(): void {
        if (this.holderDiv.current)
        this.onVisible(this.holderDiv.current, (e: Element) =>
        {
            this.refreshData();
        });

      
    }


    render() {

        return (
            <>
               <div className="thumbnail-container" ref={this.holderDiv}>
                {
                    this.state.errorMessage ?
                    <div className="thumbnailError">{this.state.errorMessage}</div>
                    :
                    <><LoadingWidget visible={!this.state.dataLoaded} zIndexLayer={0}></LoadingWidget>
                    <ChartD3
                      ref={this.ttfmChartd3}
                      id={"FLOWDIAG"+this.props.parentGroup+this.props.measurementPreview.id}
                      autoscroll={true}
                      width={this.props.width}
                      height={this.props.height}
                      yaxismin={0}
                      yaxismax={10}
                      xaxisposition={0}
                      xaxismax={1100}
                      yAxisVisible={this.props.yAxisVisible}
                      xAxisVisible={this.props.xAxisVisible}
                      color="var(--WaterySea)"
                      colorOutline="var(--dolphin)"
                      yAxisLabel=""
                      margintop={5}
                      marginleft={5}
                      marginright={5}
                      marginbottom={5}
                      xAxisLabelsFormat={()=>{return"";}}
                      yAxisLabelsFormat={()=>{return"";}}
  
                      yAxisMinimal={true}
                      ></ChartD3></>
                }
                     </div>
            </>
        );
    }
}
