import React, { ReactElement } from "react";
import { PreviewMeasurement } from "../Models/PreviewMeasurement";
import { StudyModel } from "../Models/StudyModel";
import { DataHelper } from "../Helpers/DataHelper";
import { MiniTTFMCurve } from "./MiniTTFMCurve";
import { LabelModel } from "../Models/LabelModel";
import { LargePreviewWindow } from "./LargePreviewWindow";
import { ClusterEvalModel } from "../Models/ClusterEvalModel";

export class MeasurmentThumbnail extends React.Component<
    {
        rowData: PreviewMeasurement,
        studySelected: StudyModel,
        dataHelper: DataHelper,
        detailed: boolean, 
        previewAvailable: boolean,
        context: string, 
        procedureId: string,
        isExpandable: boolean
    },
    {
        largePreview: boolean
        evaluationResult: ClusterEvalModel;
    }
> {

    popupRef: React.RefObject<LargePreviewWindow>;
    constructor(props: any) {
        super(props);
        this.popupRef = React.createRef();
        this.state = {
            largePreview: false,
            evaluationResult: new ClusterEvalModel()
        }
    }

    static defaultProps = {
        //Set any properties that are optional when creating the component.
        context: "",
        previewAvailable: true,
        isExpandable: true
      }

    
    componentDidMount(): void {
        //Put stuff here that you want to run the first time the component is loaded.
    }
    stringToColor(str: string): string {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        const hue = (hash*19) % 360;
        return `hsl(${hue}, 80%, 50%)`; // Here, we set the saturation to 100% and lightness to 75%
      }

    handleEvaluationResult = (resultDict: ClusterEvalModel) => {
        this.setState({
            evaluationResult:resultDict
        })
    };


    
    
    render() {
        let fullAnatomicalLocation = this.props.rowData.anatomicalLocations?.join("-") as string;
        if (!fullAnatomicalLocation)
          fullAnatomicalLocation = this.props.rowData.vessels?.join("-") as string;
            let labelToUse = fullAnatomicalLocation ? <div className={"tileAnatomicalLabel"} title={fullAnatomicalLocation}>{fullAnatomicalLocation}</div> : <></>;
        let itemOut: ReactElement = <></>;
        if (this.props.detailed)
        {
            if (this.props.rowData.missingData)
            {
                itemOut = <div className="missing-thumbnail"><img alt="Missing data" src="/noIMG.svg" title="Missing data" loading="lazy"/></div>;
            }else
            {
            if (this.props.rowData.measurementType === "Flow")
            {
                itemOut = <div className="flowThumb"><MiniTTFMCurve parentGroup={this.props.context} procedureid={this.props.procedureId} studySelected={this.props.studySelected} dataReceiver={this.props.dataHelper} measurementPreview={this.props.rowData}></MiniTTFMCurve></div>;
            }
            if (this.props.rowData.measurementType === "Ecg")
            {
                itemOut = <div className="flowThumb"><MiniTTFMCurve parentGroup={this.props.context} procedureid={this.props.procedureId} color1="#4A90E2" yAxisVisible={false} xAxisVisible={false}  height={60} usefilling={false} subType="Ecg" studySelected={this.props.studySelected} dataReceiver={this.props.dataHelper} measurementPreview={this.props.rowData}></MiniTTFMCurve></div>;
            }
            if (this.props.rowData.measurementType === "PWDopplers")
            {
                itemOut = <div className="flowThumb"><MiniTTFMCurve parentGroup={this.props.context} procedureid={this.props.procedureId} color1="#0196C2" color2="#C0E8F2" color3="#2A6BFF" usefilling={false} subType="PWDopplers" studySelected={this.props.studySelected} dataReceiver={this.props.dataHelper} measurementPreview={this.props.rowData}></MiniTTFMCurve></div>;
            }
            else if (this.props.rowData.measurementType === "Imaging")
            {
                itemOut = <div className="imaging-thumbnail"><img alt="Imaging preview"  src={this.props.dataHelper.hostAddress + "MiscData/GetImagingThumbnail?studyId=" + this.props.studySelected.id + "&measurementId=" +  this.props.rowData.id + "&procedureId=" +  this.props.procedureId+ "&tokenId=" +  this.props.dataHelper.miscToken} loading="lazy"/></div>;
            }
        }
        }else
        {
            if (this.props.rowData.missingData)
                {
                    itemOut = <div className="missing-thumbnail-simple">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="29" viewBox="0 0 80 72">
                                    <g id="Group_11270" data-name="Group 11270" transform="translate(0 -4)">
                                    <path id="Path_11899" data-name="Path 11899" d="M37.656,12.815a9.37,9.37,0,0,1,16.235,0L84.528,66.2A9.309,9.309,0,0,1,76.41,80.125H15.137A9.309,9.309,0,0,1,7.019,66.2Z" transform="translate(-5.773 -4.125)" fill="#f5db00"/>
                                    <path id="Union_100" data-name="Union 100" d="M5163-3972a5,5,0,0,1,5-5,5.136,5.136,0,0,1,3.957,1.945A4.762,4.762,0,0,1,5173-3972a5,5,0,0,1-5,5A5,5,0,0,1,5163-3972Zm0-17v-18a5,5,0,0,1,5-5,5,5,0,0,1,5,5v18a5,5,0,0,1-5,5A5,5,0,0,1,5163-3989Z" transform="translate(-5128 4034)"/>
                                    </g>
                                </svg>
                                <div className="missingLabel">Missing data</div>
                            </div>;
                }else
                {
                    if (this.props.rowData.measurementType === "Flow")
                    {
             
                       
                      let meanValue= Math.abs((this.props.rowData.mean as number *1000));
                      let lowerValue = this.props.studySelected.lowerReferenceValue as number;
                      let upperValue = this.props.studySelected.upperReferenceValue as number;
                      let meanflowClassname = ( meanValue< lowerValue? "meanflowThumbnailRed": meanValue > upperValue? "meanflowThumbnailGreen": "meanflowThumbnailYellow")

                      itemOut =<div className={"tile-thumbnail-simple "}>
                        <div className={"meanflowThumbnail " + meanflowClassname} >{labelToUse}<div className="thumbnail-primaryindex"><div className="thumbTitle">MF</div><div>
                            {(this.props.rowData.mean ? this.props.rowData.mean *1000 : 0.0).toFixed(2)}</div></div>
                            <div className="thumbnail-secondaryindex"><div className="thumbTitle">PI</div><div>
                            {(this.props.rowData.pI ? this.props.rowData.pI : 0.0).toFixed(2)}</div></div>
                            </div>
                            </div>;
                      
                    }
                    else if (this.props.rowData.measurementType === "Imaging")
                    {
                      if (this.props.previewAvailable)
                          itemOut = <div className="imaging-thumbnail-simple">{labelToUse}<img alt="Imaging preview" src={this.props.dataHelper.hostAddress + "MiscData/GetImagingThumbnail?studyId=" + this.props.studySelected.id + "&measurementId=" + this.props.rowData.id + "&procedureId=" +  this.props.procedureId+ "&tokenId=" +  this.props.dataHelper.miscToken} loading="lazy"/></div>;
                        else
                          itemOut = <div className="imaging-thumbnail-simple">{labelToUse}<img alt="Imaging preview" src="/usimage.png" loading="lazy"/></div>;
                    }
                    else if (this.props.rowData.measurementType === "Ecg")
                    {
                        itemOut = <></>;// Mini Preview of ECG is blank <div className="imaging-thumbnail-simple">ECG</div>;
                    }
                    else if (this.props.rowData.measurementType === "PWDopplers")
                    {
                        itemOut = <></>;// Mini Preview of ECG is blank <div className="imaging-thumbnail-simple">ECG</div>;
                    }
                    else
                    {
                        itemOut = <div className="unknown-thumbnail-simple">Unknown</div>;
                    }
                }
        }
        let suggestedLabels: string[] = [];
          
        if (this.props.rowData.labels) {
            this.props.rowData.labels.forEach((label: LabelModel) => {
            suggestedLabels.push(label.name);
          });
        }
        
        // let uniqueLabels = Array.from(new Set(suggestedLabels));
        // let labelsStyled = <></>;
        // if (uniqueLabels) {
        //     labelsStyled =
        //     <div className="tagsWrapper">
        //       {uniqueLabels.map((label, index) => (
        //         <div key={index} className={"labelInDataGrid"} style={{backgroundColor: this.stringToColor(label)}}>
        //           {label}
        //         </div>
        //       ))}
        //     </div>
        
        // }
        return (
            
            
            <>
              { this.state.largePreview && <LargePreviewWindow onEvaluationResult={this.handleEvaluationResult} ref={this.popupRef} rowData={this.props.rowData} dataHelper={this.props.dataHelper} procedureId={this.props.procedureId} studySelected={this.props.studySelected} closeClicked={()=>{
                  this.setState({largePreview: false});
              }} cachedEvalResutls={this.state.evaluationResult as ClusterEvalModel} ></LargePreviewWindow>}
            <div className="thumbItem" onClick={(e)=>
            {
                if (this.props.isExpandable)
                    {
                this.setState({largePreview: true});
                e.stopPropagation();
                    }
            }}>
              {
                itemOut
              }
            </div>
            </>
        );
    }
}
