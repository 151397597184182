import React, { ReactNode } from 'react';
import { DataGridColumn, DataGridRow, SortVal } from '../Helpers/SharedClasses';
import { InputItem } from './InputItem';
import { StarPreview } from './StarPreview';
import { PiArrowRight,  PiArrowLeft } from "react-icons/pi";
import { PreviewMeasurement } from '../Models/PreviewMeasurement';
import { LabelModel } from '../Models/LabelModel';
import { CryptographyHelper } from "../Helpers/CryptographyHelper";
import { StudyModel } from '../Models/StudyModel';

//This is a little DataGrid for basic viewing to be replaced or expanded with more functionality
class DataGrid extends React.PureComponent<{popupWindow?:boolean, returnErrorBox?:(error:string)=>void, returnInfoBox?:(info:string)=>void ,showSummary: boolean,chosenOptionsDropdown:string[],usePaging: boolean, maxNumPagesShown: number, pageSize: number, usePagingChild: boolean, pageSizeChild: number, extraclass: string, sort?: (name: string, value: any) => void, studySelected?: StudyModel, columns: DataGridColumn[], childcolumns?: DataGridColumn[], rows: DataGridRow[], rowSelected?: (rowdata: DataGridRow) => void, onChange: (rowdata: DataGridRow, columnChanged: string, value: any, index?: number, submitToServer?: boolean) => void, onChildChange?: (rowdata: DataGridRow, parentrowdata: DataGridRow, columnChanged: string, value: any, index?: number, parentIndex?: number, submitToServer?: boolean) => void, hasHierarchy?: boolean, isFilterable?: boolean, childrenpropertyname?: string, grouping?: boolean, groupingpropertyname?: string, getRowAlternateColor?: (item: DataGridRow) => boolean, getChildGridRowAlternateColor?: (item: DataGridRow) => boolean, parentRow: DataGridRow }, { page: number, encryptionKey:string, selectedIndex: number, sortVal: SortVal, appliedFilter: SortVal[],appliedLabels:string[], filterVal: string, encryptedToDecryptedMap: { [key: string]: string }, filterChildren: boolean}> {

  constructor(props: any) {
    super(props);
    this.state = {
      page: 0,
      appliedFilter: [],
      sortVal: new SortVal(),
      filterVal: "",
      selectedIndex: -1,
      appliedLabels:[],
      encryptedToDecryptedMap: {},
      encryptionKey:"", 
      filterChildren: true
    };
  }
  static defaultProps = {
    showSummary: true,
        chosenOptionsDropdown:[],
    usePaging: false,
    pageSize: 10,
    studySelected: null,
    usePagingChild: false,
    pageSizeChild: 10,
    maxNumPagesShown: 10,
    extraclass: "",
    rowSelected: null,
    editable: false,
    onChange: null,
    hasHierarchy: false,
    isFilterable: false,
    valueList: [],
    groupingpropertyname: "",
    grouping: false,
    parentRow: null,
    getRowAlternateColor: (item: DataGridRow) => {
      return item.index % 2;
    },
    getChildGridRowAlternateColor: (item: DataGridRow) => {
      return item.index % 2;
    }
  }

  renderIntValueInGrid = (item: any) => {
    if (item as number && item !== 0) {
      return <div>{(item as number).toFixed(0)}</div>
    } else {
      return <div>--</div>
    }
  }
  
  renderGenderValueInGrid = (item: any) => {
    if (item as number && item !== 0) {
      if (item === 1)
      return <div>M</div>
      else
      return <div>F</div>;
    } else {
      return <div>--</div>
    }
  }

  handleDecryption = async (pin:string) => {
    const enc = new CryptographyHelper();
    const procedures = this.props.rows;
  
    const decryptValue = async (value: string, attemptCount: number = 0): Promise<string> => {
      try {
      if (attemptCount > 15) {
        console.warn(`Failed to decrypt value after 10 attempts: ${value}`);
        //this.props.returnErrorBox?.("Failed to decrypt value after 10 attempts")
        return value;
      }
      const decrypted = await enc.decryptValue(value || "", pin);
      if (decrypted.startsWith("ENC:")) {
        return decryptValue(decrypted, attemptCount + 1);
      }
      return decrypted;
    }catch(ex: any)
    {
      console.log("Decryption failed: " + ex.message)
      return value;
    }
    };
  
    try {
      const newEncryptedToDecryptedMap: { [key: string]: string } = {};
  
      for (const procedure of procedures) {
        const typedProcedure: any = procedure;
        console.log("Processing procedure:", procedure); // Log the entire procedure object
  
        if (typedProcedure.patientId && typedProcedure.patientId.startsWith("ENC:")) {
          const decryptedId = await decryptValue(typedProcedure.patientId);
          
          newEncryptedToDecryptedMap[typedProcedure.patientId] = decryptedId;
        } else {
          console.log("PatientId not encrypted or not present:", typedProcedure.patientId);
         // this.props.returnErrorBox?.("PatientId not encrypted or not present");
        }
      }
    
      this.props.returnInfoBox?.("Decryption was Successful!")
      console.log("Final encrypted to decrypted map:", newEncryptedToDecryptedMap);
  
      this.setState({
        encryptedToDecryptedMap:newEncryptedToDecryptedMap,
        encryptionKey:pin

      });
    } catch (error) {
      this.props.returnErrorBox?.("Make sure to input correct pin");
      console.error("Error decrypting IDs:", error);
    }
  }

  renderFromType =  (parentRow: DataGridRow | any, columnDetail: DataGridColumn, value:any, type?: string, editable?: boolean, valueList?: string[], getValueList?: (item: any)=> string[], index?: number, firstRowOfAlternateGroup?: boolean): ReactNode =>
  {
    if (columnDetail.ShowValueOncePerAlternate && !firstRowOfAlternateGroup)
    {
      //If ShowValueOncePerAlternate is set to true then only show the value only per alternate group.
      return <></>;
    }
    const columnName = columnDetail.Name;
    const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');
    if (!editable)
    {
      if (type === "boolean")
      {
        return value ? <><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg></> : <></>;
      } 
      else  if (this.props.studySelected?.idFieldForStudy===columnName && !value)
        {
            return <span className="blank-study-id">
              {value}
              <svg xmlns="http://www.w3.org/2000/svg" width="80" height="72" viewBox="0 0 80 72">
                <g id="Group_11270" data-name="Group 11270" transform="translate(0 -4)">
                  <path id="Path_11899" data-name="Path 11899" d="M37.656,12.815a9.37,9.37,0,0,1,16.235,0L84.528,66.2A9.309,9.309,0,0,1,76.41,80.125H15.137A9.309,9.309,0,0,1,7.019,66.2Z" transform="translate(-5.773 -4.125)" fill="#f5db00"/>
                  <path id="Union_100" data-name="Union 100" d="M5163-3972a5,5,0,0,1,5-5,5.136,5.136,0,0,1,3.957,1.945A4.762,4.762,0,0,1,5173-3972a5,5,0,0,1-5,5A5,5,0,0,1,5163-3972Zm0-17v-18a5,5,0,0,1,5-5,5,5,0,0,1,5,5v18a5,5,0,0,1-5,5A5,5,0,0,1,5163-3989Z" transform="translate(-5128 4034)"/>
                </g>
              </svg>
              </span>;
        }
      else if (type === "int")
        {
          return this.renderIntValueInGrid(value);
        }else if (type === "gender")
          {
              return this.renderGenderValueInGrid(value);
          }
      else if (type === "date")
      {
          if (value)
          {
           
              let dateParsed: Date = new Date(value);
              return <>{zeroPad(dateParsed.getDate(), 2)  + "-" + zeroPad(dateParsed.getMonth()+1, 2) + "-" + zeroPad(dateParsed.getFullYear(), 2)}</>;
          }
 
      }
      else if (type === "datenoday")
        {
            if (value)
            {
             
                let dateParsed: Date = new Date(value);
                return <>{"XX-" + zeroPad(dateParsed.getMonth()+1, 2) + "-" + zeroPad(dateParsed.getFullYear(), 2)}</>;
            }
   
        }   else if (type === "time")
      {
          if (value)
          {

              let dateParsed: Date = new Date(value);
              return <>{zeroPad(dateParsed.getHours(),2)  + ":" + zeroPad(dateParsed.getMinutes()+1, 2) + ":" + zeroPad(dateParsed.getSeconds(), 2)}</>;
          }
 
      }
      else if (type === "uploadstatus")
      {
        if (value === 0) //Not started
          return <span className='animeCell noAnimation'>Waiting...</span>;
        else if (value === 1)//Starting
          return <span className='animeCell sartingAnimation'><span className="loader"></span><label>Processing...</label></span>;
        else if (value === 2)//Finishing up 
          return <span className='animeCell finishingAnimation'><span className="loader"></span><label>Finishing...</label></span>;
        else if (value === 3)//Completed
          return <span className='animeCell completingAnimation'><span className="gg-check"></span>Completed.</span>;
      }
      else if (type === "star")
      {

      //   let styleType = 'grid-star-item';
      //   if (value >= 100)
      //   {
      //     value = value -100;
      //     styleType = 'grid-star-item-blue';
      //   }
      //  let floorVal = Math.floor(value);
      
      //  let starContent = [];
      //  for (let lc = 0; lc < floorVal; lc++)
      //  {
      //   starContent.push(<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.322 2.923c.126-.259.39-.423.678-.423.289 0 .552.164.678.423.974 1.998 2.65 5.44 2.65 5.44s3.811.524 6.022.829c.403.055.65.396.65.747 0 .19-.072.383-.231.536-1.61 1.538-4.382 4.191-4.382 4.191s.677 3.767 1.069 5.952c.083.462-.275.882-.742.882-.122 0-.244-.029-.355-.089-1.968-1.048-5.359-2.851-5.359-2.851s-3.391 1.803-5.359 2.851c-.111.06-.234.089-.356.089-.465 0-.825-.421-.741-.882.393-2.185 1.07-5.952 1.07-5.952s-2.773-2.653-4.382-4.191c-.16-.153-.232-.346-.232-.535 0-.352.249-.694.651-.748 2.211-.305 6.021-.829 6.021-.829s1.677-3.442 2.65-5.44z" fill-rule="nonzero"/></svg>);
      //  }
      //   if (value - floorVal >= 0.5)
      //   {
      //     starContent.push(<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.322 2.923c.126-.259.39-.423.678-.423.289 0 .552.164.678.423.974 1.998 2.65 5.44 2.65 5.44s3.811.524 6.022.829c.403.055.65.396.65.747 0 .19-.072.383-.231.536-1.61 1.538-4.382 4.191-4.382 4.191s.677 3.767 1.069 5.952c.083.462-.275.882-.742.882-.122 0-.244-.029-.355-.089-1.968-1.048-5.359-2.851-5.359-2.851s-3.391 1.803-5.359 2.851c-.111.06-.234.089-.356.089-.465 0-.825-.421-.741-.882.393-2.185 1.07-5.952 1.07-5.952s-2.773-2.653-4.382-4.191c-.16-.153-.232-.346-.232-.535 0-.352.249-.694.651-.748 2.211-.305 6.021-.829 6.021-.829s1.677-3.442 2.65-5.44zm.678 2.033v11.904l4.707 2.505-.951-5.236 3.851-3.662-5.314-.756z" fill-rule="nonzero"/></svg>);
      //   }
        return <div className="datagridStars"><StarPreview value={value}></StarPreview></div>;
      }
      else if (type === "encryptable")
      {
        let valueToShow = value;
        let titleToShow = "";
        if (valueToShow)
        {
            if (valueToShow.substring(0,4) === "ENC:")
            {
                titleToShow = "Password required to view this value";
                if (this.state.encryptionKey)
                {
                if (this.state.encryptedToDecryptedMap[value])
                {

                      titleToShow = "This item was unlocked using a password.";
                      valueToShow = this.state.encryptedToDecryptedMap[value];
                    

                    if (valueToShow && valueToShow.substring(0,4) === "ENC:")
                    {
                        titleToShow = "The password given was invalid for this item.";
                        valueToShow = "********";
                    }
                }
              }else
              {
                valueToShow = "********";
              }
            }
        }

        return <div  className={"data-grid-cell"}  title={titleToShow}>{valueToShow}</div>;
      }
       else 
      {
          return <div  className={"data-grid-cell"}  title={value}>{value}</div>;
      }
    }
    else
    {
      if (type==="dropdown")
      {
        return <select value={parentRow[columnName] ? parentRow[columnName] : ""  } onClick={(e)=>
        {
          e.preventDefault();
          e.stopPropagation();
        }} onChange={(event)=>
        {
          
          parentRow[columnName] = event.target.value;
            if (this.props.onChange)
              this.props.onChange(parentRow, columnName, event.target.value, index, true);
        }} >
          { getValueList ? getValueList(parentRow).map(
optionName=>
{
return <option key={"option_" + parentRow.index +"_" + columnName+ "_" + optionName} value={optionName}>{optionName}</option>;
}

          ): valueList ? valueList.map(optionName=>
          {
           return <option key={"option_" + parentRow.index +"_" + columnName+ "_" + optionName} value={optionName}>{optionName}</option>;
          }) : null}
          </select>;
        
      }else if (type==="textarea")
      {
        if (!value)
          value ="";
        return <textarea value={(value ? value : parentRow[columnName]) ?(value ? value : parentRow[columnName])  : "" } className='tableCommentsText' onClick={(e)=>
          {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }} 
          onBlur={(e)=>
            {
              parentRow[columnName] = (e.target as HTMLTextAreaElement).value;
              if (this.props.onChange)
                this.props.onChange(parentRow, columnName, (e.target as HTMLTextAreaElement).value, index, true);
            }}
            onChange={(e)=>
              {

                parentRow[columnName] = (e.target as HTMLTextAreaElement).value;
          
                if (this.props.onChange)
                {
                  this.props.onChange(parentRow, columnName, (e.target as HTMLTextAreaElement).value, index, false);
                }
              }
            }
          ></textarea>
      }
      
      else if (type === "boolean")
      {
        type = "checkbox";
        
        return <label><input  type={type} checked={value}
            onChange={(e)=>
              {
               
                  parentRow[columnName] = (e.target as HTMLInputElement).checked;
                  if (this.props.onChange)
                    this.props.onChange(parentRow, columnName, (e.target as HTMLInputElement).checked, index, false);
                
              }
            }
          /></label>
      } else 
      {
      
       
        return <input type={type} value={value} onClick={(e)=>
        {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }} 
        onBlur={(e)=>
          {
       
            parentRow[columnName] = (e.target as HTMLInputElement).value;
            if (this.props.onChange)
              this.props.onChange(parentRow, columnName, (e.target as HTMLInputElement).value, index, true);
    
          }}
          onChange={(e)=>
            {
       
              parentRow[columnName] = (e.target as HTMLInputElement).value;
              if (this.props.onChange)
                this.props.onChange(parentRow, columnName, (e.target as HTMLInputElement).value, index, false);
           
            }
          }
        ></input>
      }
    }
  }


  selectAllRows = (columnName: string, value: boolean) => {
    for (let index = 0; index < this.props.rows.length; index++) {
      let row = this.props.rows[index];
      (row as any)[columnName] = value;
      if (this.props.onChange)
        this.props.onChange(row, columnName, value, index, false);

    }
  }

  removeFilter(name: string, value: any) {
    let appliedFilterCopy = [...this.state.appliedFilter];
    if (value === '-1') {
      appliedFilterCopy.forEach((filter, index) => {
        if (filter.category === name) {
          appliedFilterCopy.splice(index, 1);
        } else if (filter.category === 'measurements' && filter.measurementType === name) {
          appliedFilterCopy.splice(index, 1);
        }
      });
    }
    else {
      appliedFilterCopy.forEach((filter, index) => {
        if (filter.category === name && filter.value === value) {
          appliedFilterCopy.splice(index, 1);
        }
      });
    }
    this.setState({ appliedFilter: appliedFilterCopy }, ()=>{
      this.forceUpdate();
    })
  }

  handleSort = (caption:string, name: string, value: any, valueEnd?: any, measurementType?: string, addFilter?: boolean, overrideType: string = "", ignoreChildren: boolean = false, searchChildren: boolean = false) => {
    let sort = new SortVal();
    sort.category = name;
    sort.value = value
    sort.capiton=caption
    sort.overrideType = overrideType;
    sort.ignoreChildren = ignoreChildren;
    sort.searchChildren = searchChildren;
    if (measurementType) { sort.measurementType = measurementType; }
    else if (searchChildren)
      sort.measurementType = name;

    let appliedFilterCopy = [...this.state.appliedFilter];

    if (valueEnd) { sort.valueEnd = valueEnd }
    //check if the filter exist, eiter by category, or messurement type
    if (value !== '--Select--' && ((!(appliedFilterCopy.some((sortVal) => sortVal.category === name)) || (!(appliedFilterCopy.some((sortVal) => sortVal.measurementType === measurementType)) && measurementType)) || addFilter === true)) {
   // if (value !== '--Select--' && (!(appliedFilterCopy.some((sortVal) => sortVal.category === name)) && (!(appliedFilterCopy.some((sortVal) => sortVal.measurementType === measurementType)) && measurementType !== undefined) || addFilter === true)) {
      appliedFilterCopy = [...this.state.appliedFilter, (sort)]
    }
    else {
      appliedFilterCopy.forEach((filter, index) => {
        if (filter.measurementType === measurementType) {
          if (value === '' || valueEnd === '') {
            appliedFilterCopy.splice(index, 1);
          }
          else if (value === '--Select--' || value === '') {
            appliedFilterCopy.splice(index, 1);
          }
          else if (!addFilter) {
            appliedFilterCopy[index] = sort
          }
        }
        else if (filter.category === name && name !== 'measurements') {
          if (value === '' || valueEnd === '') {
            appliedFilterCopy.splice(index, 1);
          }
          else if (!addFilter) {
            appliedFilterCopy[index] = sort
          }
        }
      })
    };
    this.setState({
      appliedFilter: appliedFilterCopy
    })

  }

  //not good

  handleDropdownSort = (row: DataGridRow, typeIn: string, value: boolean): boolean => {
    if (value) {
      return (row as any)[typeIn] === value;
    }
    else if (!value) {
      return !((row as any)[typeIn]);
    }
    else { return true }
  }

  getLabelValues(caption:string): string[] {
    return this.state.appliedFilter
        .filter(filter => filter.capiton === caption)
        .map(filter => filter.value);
      }


  removeRowSort = (row: DataGridRow, isChildGrid = false, childrenNodeName: string = "", foundInParent: any = null): boolean => {
   
    let renderVar: boolean = true;
    if (foundInParent)
      foundInParent.val = true;
    for (let i = 0; i < this.state.appliedFilter.length; i++) {
  

      if (renderVar === true) {
        let sortVal = this.state.appliedFilter[i];
        let searchColmn = this.props.columns.find(column => column.Caption === sortVal.capiton) || new DataGridColumn();
        if (sortVal.overrideType)
        {
          searchColmn.Type = sortVal.overrideType;
        }

        
        // if (sortVal.category === "hospitalName")
        // {
        //   searchColmn.Type = "dropdown";
        // }
       // if ((!isChildGrid && sortVal.measurementType !== "measurementType")&&((isChildGrid &&searchColmn.Type !== "star"  && Object.keys(row as any).indexOf(sortVal.category) > -1) || (isChildGrid &&searchColmn.Type === "interval")) ||  Object.keys(row as any).indexOf(sortVal.category) > -1)
       let filterField = sortVal.category;
       if (isChildGrid)
       {
          //Child grid filters on measurementType not category
          filterField = sortVal.measurementType as string;
         
      }
   
      if (isChildGrid &&  sortVal.ignoreChildren)
      {
        renderVar = true;
      }
     else if (Object.keys(row as any).indexOf(filterField as string) > -1 && !(!isChildGrid && filterField === "measurements"))
      {

      renderVar = false;
       
       
      //  if (isChildGrid && Object.keys(row as any).indexOf(sortVal.measurementType as string) > -1)
      //  {
        if (searchColmn.Type === "interval" || searchColmn.Type === "star")
        {
         
          if (sortVal.category === "measurements") {
            
                let c = 1
              if (sortVal.measurementType === "mean") { c = 1000 }
                if (sortVal.measurementType=== "measurementType" || sortVal.measurementType=== "phasicityPreview" || sortVal.measurementType=== "operatingMode")
                {
                  renderVar = (this.handleDropdownSort(row, filterField, sortVal.value));
                }else if (sortVal.measurementType=== "id")
                {
                  renderVar= (row as any)[sortVal.measurementType].toLowerCase().includes(sortVal.value.toLowerCase());
                }else 
                if (Math.abs((row as any)[sortVal.measurementType || 'mean'] * c) >= parseFloat(sortVal.value) && (Math.abs((row as any)[sortVal.measurementType || 'mean'] * c)) <= parseFloat(sortVal.valueEnd) && (row as any)[sortVal.measurementType || 'mean']) 
                { 
                  renderVar = true; 
                }
              }
          else {
            let num = (row as any)[filterField];
            renderVar = (sortVal.value <= num && num <= sortVal.valueEnd);
          }
        }
        else if (searchColmn.Type === "dropdown") {
          renderVar = (this.handleDropdownSort(row, filterField, sortVal.value));
        }

            else if(searchColmn.Type==="boolean"){
              if(sortVal.value==='true'){renderVar=(row as any)[filterField]}
              else if(sortVal.value==='false'){renderVar=!((row as any)[filterField])}
              else{renderVar = true}
            }
            else if(searchColmn.Type==="dropdownMultipleSelect" && sortVal.category==="labels" && sortVal.capiton === "Anatomical locations"){
              let rowVal ="";
              if((row as PreviewMeasurement)["labels"]){
                rowVal=(row as PreviewMeasurement)["labels"]?.map((label) => label.id).join(', ')||"";

              }
              else{rowVal=""}
              if (sortVal.value === undefined || sortVal.value === null)
                sortVal.value = "";
              renderVar= rowVal.toLowerCase().includes(sortVal.value.toLowerCase());
            }
            else if(searchColmn.Type==="dropdownMultipleSelect" && sortVal.category==="labels" && sortVal.capiton === "Labels"){
              
              let labelFound = false;
              let measelement = row;
              if (((measelement as PreviewMeasurement).labels as LabelModel[]))
              {
               
              for (let index = 0; index < ((measelement as PreviewMeasurement).labels as LabelModel[]).length; index++) {
                const element = ((measelement as PreviewMeasurement).labels as LabelModel[])[index];
                let foundList = element.suggestedLabels?.filter((suggLabel) => suggLabel.name === sortVal.value);
                if (!labelFound && foundList && foundList.length > 0)
                  labelFound = true;
                
              }
               
            }
            renderVar = labelFound;
    
            }
            else{
              let rowVal:string
              if((row as any)[sortVal.category]){
                rowVal=(row as any)[sortVal.category].toString();
                let testBreak  = "TestA Break";
                testBreak = testBreak + "test";

              }
              else{rowVal=""}
              renderVar= rowVal.toLowerCase().includes(sortVal.value.toLowerCase());
            }
            }
      
        
        else
          {
            if (foundInParent)
              foundInParent.val = false;
              //If there is no column found then just return the row because children may exist with that property.
              renderVar = true;
          }

          if (!isChildGrid && sortVal.searchChildren && !renderVar && childrenNodeName && (row as any)[childrenNodeName])
            {
                //If there is no match but we have been asked to serarch children explicitly then we search children as well.
                if ((row as any)[childrenNodeName])
                {
                    for (let index = 0; index < (row as any)[childrenNodeName].length; index++) {
                      const element = (row as any)[childrenNodeName][index];
                      if (element[sortVal.category])
                        renderVar = element[sortVal.category].includes(sortVal.value.toLowerCase());
                      if (renderVar)
                        break;
                    }
                }

            }

        }

         
          //   if(renderVar && this.props.childrenpropertyname && searchColmn.Type !== "interval"&& searchColmn.Type !== "star")
          //   {
          //     let oldChildRows = (row as any)[this.props.childrenpropertyname as string];
          //   if (oldChildRows && oldChildRows.length > 0) {
          //     oldChildRows = oldChildRows.filter((element: any) => this.removeRowSort(element));
          //     if (oldChildRows.length === 0)
          //     {
          //       renderVar = false;
          //     }
          //   }
          // }
        }
           
          // }

        return renderVar;
      }
      getFilteredData = (includeMeasurements: boolean): DataGridRow[] => {
        let rowsToUse = this.props.rows;
        // if (includeMeasurements)
        // {
        //   //Need to create a copy of this because it doesn't seem to.
        //   rowsToUse = JSON.parse(JSON.stringify(this.props.rows));
        // }
        let foundInParent = {val: false};
        if (this.state.appliedFilter.length !== 0) {
          let rowsOut: DataGridRow[]= [];
          let filterList = rowsToUse.filter((item) => { return this.removeRowSort(item, false, this.props.childrenpropertyname, foundInParent); });
          for (let index = 0; index < filterList.length; index++) {
            const element = {...filterList[index]};
            rowsOut.push(element);
          }
          
        
        
        if (includeMeasurements ) {
          for (let index = 0; index < rowsOut.length; index++) {
            const element: any = rowsOut[index];
            let oldChildRows = element[this.props.childrenpropertyname as string];
            if (oldChildRows && oldChildRows.length > 0) {

              oldChildRows =  [...oldChildRows.filter((elementMeasurement: any) => this.removeRowSort(elementMeasurement, true, this.props.childrenpropertyname))];
              if (oldChildRows.length === 0 &&!foundInParent.val)
              {
                //If a filter is applied and there are now no rows then remove it.
                rowsOut.splice(index,1);
                index--;
              }else
              {
                if (this.state.filterChildren)
                  element[this.props.childrenpropertyname as string] = oldChildRows;
              }
            }
          }
        }

       
        // else
        // {
        //   for (let index = 0; index < rowsOut.length; index++) {
        //     const element: any = rowsOut[index];
        //     let oldChildRows = element[this.props.childrenpropertyname as string];
        //     this.totalChildRows += oldChildRows.length;
        //   }
        // }
        return rowsOut;
      }

        //   for (let index = 0; index < rowsToUse.length; index++) {
        //     const element: any = rowsToUse[index];
        //     let oldChildRows = element[this.props.childrenpropertyname as string];
        //     this.totalChildRows += oldChildRows.length;
        // }
        return rowsToUse;
      }

    render() {
        let currentGroupName = "";
        let previousRowAlternate = true;
        let rowsToUse = this.props.rows;
        let totalRows = this.props.rows;

          totalRows = this.getFilteredData(true);
          //totalRows = rowsToUse.filter((item)=>{ return this.removeRowSort(item); })
        

    if (this.props.usePaging) {
      let lastIndex = (this.state.page + 1) * this.props.pageSize > totalRows.length ? totalRows.length : (this.state.page + 1) * this.props.pageSize;
      rowsToUse = totalRows.slice(this.state.page * this.props.pageSize, lastIndex);
    }
    let pagerHolder = <></>;

    if (this.props.usePaging) {
      let pageNodes: ReactNode[] = [];
      let pagesCount = Math.floor(totalRows.length / this.props.pageSize);

      if ( Math.floor(totalRows.length / this.props.pageSize) === totalRows.length / this.props.pageSize)
      {
        pagesCount--;
      }

      let pagesShownStart = 0;
      let pagesShownEnd = pagesCount;
      if (pagesCount > this.props.maxNumPagesShown) {
        pagesShownStart = this.state.page - this.props.maxNumPagesShown / 2;
        pagesShownEnd = this.state.page + this.props.maxNumPagesShown / 2;
        if (pagesShownStart < 0) {
          pagesShownEnd = pagesShownEnd - pagesShownStart;
          pagesShownStart = 0;
        }


        if (pagesShownEnd > pagesCount + 1) {
          pagesShownStart = pagesShownStart - (pagesShownEnd - (pagesCount + 1));
          pagesShownEnd = pagesCount;


        }
        if (pagesShownStart < 0) {
          pagesShownStart = 0;
        }
        if (pagesShownEnd > pagesCount) {
          pagesShownEnd = pagesCount;
        }

      }


      for (let index = pagesShownStart; index <= pagesShownEnd; index++) {
        pageNodes.push(<div key={"pager_"+index + this.props.childrenpropertyname} className={'pageNav ' + (index === this.state.page ? "pageSelected" : "")}><button onClick={() => {
          this.setState({
            page: index
          });
        }}>{index + 1}</button></div>)
      }
      pagerHolder = <div className={'pager'} style={this.props.popupWindow?{position:'relative'}:{position:'fixed'}}><div className={'pageNav'}><button onClick={() => {
        this.setState({
          page: 0
        });
      }}>|<PiArrowLeft /></button></div>
        <div className={'pageNav'}><button onClick={() => {
          this.setState({
            page: this.state.page > 0 ? this.state.page - 1 : 0
          });
        }}><PiArrowLeft /></button></div>
        {pageNodes}
        
        <div className={'pageNav'}><button onClick={() => {
          this.setState({
            page: this.state.page < Math.floor(totalRows.length / this.props.pageSize) ? this.state.page + 1 : Math.floor(totalRows.length / this.props.pageSize)
          });
        }}><PiArrowRight /></button></div>
        <div className={'pageNav'}><button onClick={() => {
          this.setState({
            page: Math.floor(totalRows.length / this.props.pageSize)
          });
        }}><PiArrowRight />|</button></div>
        {this.props.childrenpropertyname?

         <div className='grid-sub-option'>
         <InputItem type='boolean' name='FilterChildren' title={"Filter " + this.props.childrenpropertyname} value={this.state.filterChildren} onChange={(name:string, value:any) => {
 
           this.setState({
             filterChildren: value === 'true'
           });
 
         }}></InputItem>
         </div>
         : <></>
        }
       
      </div>
    }

    return <><div className={(this.props.usePaging ? "data-grid-container" : "")}><table className={"base-data-grid " + this.props.extraclass}>
      {this.props.isFilterable &&
        <thead className='theadFilter'>
          <tr>
            {this.props.columns.map((column, index) => (
              
                !column.hidden
                ?
                <th key={"Filter" + index}>
                <div className="messurementFilterContainer">
                {
                    column.AdditionalFilter ? (
                      column.AdditionalFilter(column)
                    ) : <></> 
                  }
                {

                  column.Type === "dropdown"  ? (
                    <InputItem type={column.Type || ""} value={"--Select--"} title={column.AdditionalFilter  ?  column.Caption : ""}  name={column.Name} options={["--Select--", ...column.FilterOptions || []]} onChange={(name, value) => this.handleSort(column.Caption,name, value, null, name)} />
                  ) : column.FilterTemplate ? (
                    column.FilterTemplate(column)
                  ) : column.Type === "boolean" ? (
                    <InputItem type={column.Type || ""} value={"--Select--"} title={column.AdditionalFilter ?  column.Caption : ""}  name={column.Name} onChange={(name, value) => this.handleSort(column.Caption ,name, value, null, name)} />
                  ) : column.Type === "dropdownMultipleSelect" ? (
                    <InputItem type={column.Type || ""} title={column.AdditionalFilter ?  column.Caption : ""} name={column.Name} chosenOptions={this.getLabelValues(column.Caption)} options={column.FilterOptions} onChange={(name, value) => this.handleSort(column.Caption,name, value, null, name, true)} deleteOption={(value) => this.removeFilter(column.Name, value)} />
                  ) : column.Type==="encryptable" && this.props.studySelected?.encryptionEnabled ? (
                    <button
                    onClick={async () => {
                      const pin = prompt("Please enter your PIN:");
                      if (pin !== null) {
                        this.handleDecryption(pin);
                      }}}
                    className="primaryButton">
                    Decrypt
                  </button>
                  
                  ) : (
                    <InputItem type={column.Type || ""} title={column.AdditionalFilter ? column.Caption : ""}  name={column.Name} options={column.FilterOptions} onChange={(name, value, intervalValue) => this.handleSort(column.Caption,name, value, intervalValue, "", false, "", column.ignoreChildren, column.searchChildren)} />

                  )}
                  

</div>
              </th>
                : <></>
              
              
            ))}
          </tr>
        </thead>
        
      }



      <thead className='headerLabels'>

        <tr>
          {this.props.columns.map((column, index) => {
            if (column.hidden)
              return <></>;
            let selectAllCheckbox = <></>;
            if (column.Editable && column.Type === "boolean") {

              let checked = true;

              for (let index = 0; index < this.props.rows.length; index++) {
                let row = this.props.rows[index];
                if ((row as any)[column.Name] === false)
                  checked = false;

              }
              selectAllCheckbox = <input type="checkbox" checked={checked} onChange={(event: any) => { this.selectAllRows(column.Name, event.currentTarget?.checked as boolean) }} />

            }
            let columnSuffix = "";
            let additionalClass = "";
            if (this.props.studySelected?.idFieldForStudy===column.Name)
            {
              columnSuffix = " (Study ID)";
              additionalClass = "study-id-column";
            }
            return <th key={"GridHeader" + (this.props.grouping ? "_GroupedOn_" + this.props.childrenpropertyname : "_Children_") + column.Name  + index} className={additionalClass}>{selectAllCheckbox}{column.Caption}{columnSuffix}</th>
          })}
        </tr>
      </thead>

      <thead className='headerData'>
          {this.props.showSummary?
        <tr>
          {this.props.columns.map((column, index) => {
             if (column.hidden)
              return <></>;
            if (column.SummaryTemplate)
            {
              return <td key={"GridColumnSummary" + index + column.Name}>{column.SummaryTemplate(totalRows)}</td>
            }
            if (column.Type === "interval" && column.Name !== "measurements")
            {
              let totalSum = 0;
              for (let loopindex = 0; index < totalRows.length; index++) {
                const element = totalRows[loopindex];
                  totalSum += (element as any)[column.Name] as number;
              }
              return <td key={"GridColumnTotal" + index + column.Name}>Total: {totalSum}</td>
            }
            else if (column.Type === "star")
            {
              let totalSum = 0;

              for (let loopindex = 0; index < totalRows.length; index++) {
                const element = totalRows[loopindex];
                  totalSum += (element as any)[column.Name] as number;
              }
              return <td key={"GridColumnAvg" + index + column.Name}>Avg: {totalRows.length > 0 ? (totalSum/totalRows.length).toFixed(1) : "--"}</td>
            }else if (column.Type === "boolean")
            {
              let totalSum = 0;

              for (let loopindex = 0; index < totalRows.length; index++) {
                const element = totalRows[loopindex];
                if ((element as any)[column.Name] as boolean)
                  totalSum++;
              }
              return <td key={"GridColumnTotalBool" + index + column.Name}>Total: {totalSum}</td>
            }else if (column.Type === "number" )
            {
              let totalSum = 0;
              let totalCount = 0;
              for (let loopindex = 0; loopindex < totalRows.length; loopindex++) {
                const element = totalRows[loopindex];
                if ((element as any)["measurementType"]  === "Flow" && ((element as any)[column.Name] || (element as any)[column.Name] === 0)  && !Number.isNaN((element as any)[column.Name] as number))
                {
                  totalCount++;
                  totalSum += (element as any)[column.Name] as number;
                }
              }
              return <td key={"GridColumnAvgNumber" + index + column.Name}>Avg: { totalCount > 0 ? (totalSum/totalCount).toFixed(2) : "--"}</td>
            }
            
            return <td key={"GridColumnBlank" + index + column.Name}></td>
          })}
        </tr>
        : <></>}
        </thead>

      <tbody>


        {rowsToUse.map((row: DataGridRow | any, i) => {
       //   let newChildRows: any
          let newChildRows = row[this.props.childrenpropertyname as string];
          let stillRenderRow = true;
          // if (oldChildRows && oldChildRows.length > 0) {
          //   newChildRows = oldChildRows.filter((element: any) => this.removeRowSort(element, true));
          //   if (newChildRows.length === 0)
          //   {
          //     //If a filter is applied and there are now no rows then remove it.
          //     stillRenderRow = false;
          //   }
          // }

        


          //if(this.state.sortVal){}


          let currentRowAlternate = this.props.getRowAlternateColor ? this.props.getRowAlternateColor(row) : false;
          //Assume the first row is always false for alternate
          let firstRowOfAlternateGroup = currentRowAlternate !== previousRowAlternate;

          previousRowAlternate = currentRowAlternate;


          let groupRow = <></>;
          if (this.props.grouping) {
            if (currentGroupName !== row[this.props.groupingpropertyname as string]) {
              currentGroupName = row[this.props.groupingpropertyname as string];
              groupRow = <tr key={"GridRowGrouping"} className='grid-grouping-row'><td key={"GridRowGroupingSub_" +this.props.groupingpropertyname as string} colSpan={16}>{row[this.props.groupingpropertyname as string]}</td></tr>
            }
          }


          return (
            <>
            {stillRenderRow ? 
            <>
              {groupRow}
              <tr key={"GridRow" + row.index + i} className={(this.props.rowSelected ? "selectable " : "") +
                (this.state.selectedIndex === i ? " selected " : "") +
                (this.props.getRowAlternateColor ? (currentRowAlternate ? " rowAlternate1 " : " rowAlternate2 ") : "")}
                onClick={() => {
                  if (this.state.selectedIndex !== i) {
                    this.setState({
                      selectedIndex: i
                    })
                    if (this.props.rowSelected)
                      this.props.rowSelected(row);
                  } else {
                    this.setState({
                      selectedIndex: -1
                    })
                  }

                }}>
                {this.props.columns.map((column, index) => (
                   column.hidden?
                     <></>:
                  <td key={"GridRow" + row.index  + "GridColumn" + index + column.Name + "_" + i}  className={"gridCell-" + column.Name + " " +(column.additionalChildClass ?column.additionalChildClass : "") }>{column.RenderTemplate ? column.RenderTemplate(row[column.Name], row, this.props.parentRow) : this.renderFromType(row, column, row[column.Name], column.Type, column.GetCellEdit ? column.GetCellEdit(row, column) : column.Editable, column.ValueList, column.GetValueList, i, firstRowOfAlternateGroup)}</td>
                ))}


              </tr>
              {this.state.selectedIndex === i && this.props.hasHierarchy ?



                <tr>
                  <td key={"GridSubGridRow" + row + "_" + i} colSpan={this.props.columns.length}>
                    <div className='child-table'>

                      <DataGrid key={"subgrid_"  + row + "_" + i} pageSize={this.props.pageSizeChild} usePaging={this.props.usePagingChild} onChange={(rowdata: DataGridRow, columnChanged: string, value: any, index?: number, submitToServer?: boolean) => {
                        if (this.props.onChildChange) {
                          this.props.onChildChange(rowdata, row, columnChanged, value, index, i, submitToServer);
                        }
                      }} columns={this.props.childcolumns ? this.props.childcolumns : []} rows={newChildRows} getRowAlternateColor={this.props.getChildGridRowAlternateColor} parentRow={row}></DataGrid>
                    </div>
                  </td>
                </tr>
                : <></>}
               </> : <></>}
            </>
          );
        })}

      </tbody>
 
    </table>
      {pagerHolder}
    </div>
    </>
  }


}//row[this.props.childrenpropertyname as string]  ? row[this.props.childrenpropertyname as string] : [] 

export default DataGrid;