export class CryptographyHelper
{

    getMessageEncoding = (message: string): ArrayBuffer => {
        const enc = new TextEncoder();
        return enc.encode(message);
      };

      getMessageDecoding = (dataIn: ArrayBuffer): string => {
        const enc = new TextDecoder();
        return enc.decode(dataIn);
      };


       generateAESKeyFromPassword = async  (password: string, saltIn: ArrayBuffer) => {
        const encoder = new TextEncoder();
        const passwordKey = await crypto.subtle.importKey('raw',encoder.encode(password),'PBKDF2', false,['deriveKey']);
        return await crypto.subtle.deriveKey({
          name: 'PBKDF2',
          salt: saltIn,
          iterations: 100000,
          hash: 'SHA-256',
        }, passwordKey, {
          name: 'AES-GCM',
          length: 256,
        }, true, ['encrypt', 'decrypt']);
      }

      encryptValue = async (message: string, password: string): Promise<string> => {
        //If it's already encrypted then just use it as is.
        if (message && message.startsWith("ENC:"))
          return message;
        const encoded = this.getMessageEncoding(message);
        // iv will be needed for decryption
        const iv = window.crypto.getRandomValues(new Uint8Array(12));
        const salt = window.crypto.getRandomValues(new Uint8Array(16));
        const keyGen = await this.generateAESKeyFromPassword(password, salt);
        const outE = await window.crypto.subtle.encrypt(
            { name: "AES-GCM", iv: iv },
            keyGen,
            encoded,
          );

          const combined = new Uint8Array(16 + 12 + outE.byteLength);
            combined.set(salt);
            combined.set(iv, 16);
            combined.set(new Uint8Array(outE), 16 + 12);
            let strRawDawg =Array.from(combined).map((c) => String.fromCharCode(c)).join('')

            return "ENC:"+ btoa(strRawDawg);
      };

      decryptValue = async (cipherValue: string, password: string): Promise<string> => {

        cipherValue = cipherValue.substring(4);
        var loadToBuffer = new Uint8Array(atob(cipherValue).split("").map(function(c) {
            return c.charCodeAt(0); }));

        //let loadToBuffer =this.getMessageEncoding( atob(cipherValue));

      //  let loadToBuffer = Buffer.from(cipherValue, "base64");

        const salt = loadToBuffer.slice(0, 16);
        const iv = loadToBuffer.slice(16, 28);
        const ciphertextArray = loadToBuffer.slice(28);
        const keyGen = await this.generateAESKeyFromPassword(password, salt);
        let decrypted = await window.crypto.subtle.decrypt(
            {
              name: "AES-GCM",
              iv: iv
            },
            keyGen,
            ciphertextArray
          );

            return this.getMessageDecoding(decrypted);
      }; 

}