import * as React from 'react';
import TrafficLightTile from './TrafficLightTile';
import GaugeTile from './GaugeTile';
import { StudyModel } from '../Models/StudyModel';
import { studyTypes } from '../Helpers/SharedClasses';


class TTFMTileHolder extends React.PureComponent<{
    studySelected: StudyModel,
    context: string,
    tileMode : string,
    ACI: number | string,
    MAP: string | number, 
    tilesExpanded: boolean,
    tileClicked: Function,
    mapStale: boolean,
    DiastolicFilling: number | string,
    BackFlow: number | string,
    PulsalityIndex: number | string,
    BeatsPerMinute: number | string, 
    MeanFlow: number | string, 
    usefilling: boolean,
    tileValueChanged: Function,
    overrideName: string,
    ttfmCurves: number,
    tileLimits: any
}> {

    bfGauge: React.RefObject<GaugeTile>;
    mfGauge: React.RefObject<GaugeTile>;
    piGauge: React.RefObject<GaugeTile>;
    dfGauge: React.RefObject<GaugeTile>;
    constructor(props: any) {
        super(props);
        this.bfGauge = React.createRef();
        this.mfGauge = React.createRef();
        this.dfGauge = React.createRef();
        this.piGauge = React.createRef();
        this.state = { 
            comparisonSelected: false,
            selectedIndex: 0
        }
    }

    componentDidMount(){

    }

    static defaultProps = {
    tilesExpanded: false,
    mapStale: false,
    overrideName: "",
    ttfmCurves: 1,
    tileClicked: ()=>
    {

    },
    
    tileValueChanged: ()=>
    {
        
    }

    }
    getTileVisibility = () => {
        const { studySelected } = this.props;
        return {
            showCardiacTiles: !studySelected.studyType || 
                      studySelected.studyType === studyTypes.None || 
                      studySelected.studyType === studyTypes.Cardiac,
            // add more visibility flags here as needed
            // Example: showVascular: studySelected.studyType === studyTypes.Vascular
        };
    }

    performResize = () => {

    
    if (this.props.tileMode === "Gauges") {

        let bfGauge = this.bfGauge.current as GaugeTile;
        if (bfGauge) 
            bfGauge.resizeGauge();
        let dfGauge = this.dfGauge.current as GaugeTile;
        if (dfGauge) 
            dfGauge.resizeGauge();
        let mfGauge = this.mfGauge.current as GaugeTile;
        if (mfGauge) 
            mfGauge.resizeGauge();
        let piGauge = this.piGauge.current as GaugeTile;
        if (piGauge) 
            piGauge.resizeGauge();

        }
        this.refreshAllTiles();

    }

    refreshAllTiles = () =>
    {
        if (this.bfGauge.current as GaugeTile)
            (this.bfGauge.current as GaugeTile).drawReferenceValuesGauge();
            if (this.mfGauge.current as GaugeTile)
            (this.mfGauge.current as GaugeTile).drawReferenceValuesGauge();
            if (this.piGauge.current as GaugeTile)
            (this.piGauge.current as GaugeTile).drawReferenceValuesGauge();
            if (this.dfGauge.current as GaugeTile)
            (this.dfGauge.current as GaugeTile).drawReferenceValuesGauge();


    }

    render() {

    let tileHolder: React.ReactElement;
    const { showCardiacTiles } = this.getTileVisibility();
    if (this.props.tileMode === "Tiles")
    {
        tileHolder = <>
        <TrafficLightTile
                            context={this.props.context}
                            key="ACI"
                            value={  typeof this.props.ACI !== "number" ? "--" : Math.round(this.props.ACI as number)}
                            title="ACI"
                            uom="%"
                            enableColouring={true}
                            worstRange={{
                            min: 0,
                            max: 20
                        }}
                            badRange={{
                            min: 20,
                            max: 50
                        }}
                            goodRange={{
                            min: 50,
                            max: 102
                        }} tileClicked={this.props.tileClicked} expanded={this.props.tilesExpanded}></TrafficLightTile> 
                        <TrafficLightTile
                        context={this.props.context}
                    key="MAP"
                    enableColouring={true}
                    value={this.props.MAP as number}
                    valueStale={this.props.mapStale}
                    title={showCardiacTiles? "MAP" : "SYS"}
                    uom="mm Hg"
                    defaultColor="tile-no-data"
                    wideTile={true}
                    goodRange={{
                    min: 60,
                    max: 200
                }}
                    badRange={{
                    min: 0,
                    max: 60
                }}
                    worstRange={{
                    min: 0,
                    max: 0
                }}
                    includeEdit={true}
                    tileValueChanged={this.props.tileValueChanged}
                    tileClicked={this.props.tileClicked}
                    expanded={this.props.tilesExpanded}></TrafficLightTile> 
                    <TrafficLightTile
                    enableColouring={true}
                    context={this.props.context}
                key = "MeanFlow"
                value = {
                    typeof this.props.MeanFlow !== "number"  ? "--" : Math.round(this.props.MeanFlow as number)
                }
                title = "MF"
                uom = "mL/min"
                wideTile = {
                    true
                }
                {...this.props.tileLimits.meanFlow}
                        tileClicked = {
                    this.props.tileClicked
                }
                expanded = {
                    this.props.tilesExpanded
                } > </TrafficLightTile> <TrafficLightTile
                context={this.props.context}
                key = "PulsalityIndex"
                value = {
                    typeof this.props.PulsalityIndex !== "number"  ? "--" : (this
                        .props
                        .PulsalityIndex as number)
                        .toFixed(1) as any
                }
                title = "PI"
                uom = ""
                maxValue = {
                    99
                }
                enableColouring={true}
                {...this.props.tileLimits.pulsalityIndex}
                tileClicked = {
                    this.props.tileClicked
                }
                expanded = {
                    this.props.tilesExpanded
                } > </TrafficLightTile> 
                {showCardiacTiles && (
                    <TrafficLightTile
                    context={this.props.context}
                    key = "DiastolicFilling"
                    value = {
                        typeof this.props.DiastolicFilling !== "number"  ? "--" : Math.round(this.props.DiastolicFilling as number)
                    }
                    enableColouring={true}
                    {...this.props.tileLimits.diastolicFilling}
                    title = "DF"
                    uom = "%"
                    includeSubValue = {
                        true
                    }
                
                    subValue = {
                        typeof this.props.BeatsPerMinute !== "number" ? "--" : Math.round(this.props.BeatsPerMinute as number)
                    
                    }
                    subValueUom = "BPM" tileClicked = {
                        this.props.tileClicked
                    }
                    expanded = {
                        this.props.tilesExpanded
                    } > </TrafficLightTile> )}
            
            
        
            </>
        
        
    }else 
    {
    tileHolder =  <>
    <div className="left-tiles-container">
    <TrafficLightTile
    context={this.props.context}
                        key="ACI"
                        value={  typeof this.props.ACI !== "number" ? "--" : Math.round(this.props.ACI as number)}
                        title="ACI"
                        uom="%"
                        referenceValueStyle="Stacked"
                        enableColouring={true}
                        // {...this.context.tileLimits.ACI}
                        worstRange={{
                        min: 0,
                        max: 20
                    }}
                        badRange={{
                        min: 20,
                        max: 50
                    }}
                        goodRange={{
                        min: 50,
                        max: 102
                    }} 
                    tileClicked={this.props.tileClicked} expanded={this.props.tilesExpanded}></TrafficLightTile > <TrafficLightTile
                    key="MAP"
                    value={this.props.MAP as any}
                    valueStale={this.props.mapStale}
                    referenceValueStyle="Stacked"
                    title={showCardiacTiles ? "MAP" : "SYS"}
                    uom="mm Hg"
                    defaultColor="tile-no-data"
                    wideTile={true}
                    enableColouring={true}
                    // {...this.context.tileLimits.MAP}
                    goodRange={{
                    min: 60,
                    max: 200
                }}
                    badRange={{
                    min: 0,
                    max: 60
                }}
                    worstRange={{
                    min: 0,
                    max: 0
                }}
                    includeEdit={true}
                    tileValueChanged={this.props.tileValueChanged}
                    tileClicked={this.props.tileClicked}
                    expanded={this.props.tilesExpanded}></TrafficLightTile> </div>
                
                
                    <GaugeTile
                    overridename={this.props.overrideName}
                    sizeFactor={this.props.ttfmCurves}
                    context={this.props.context}
                    ref={this.mfGauge}
                    key="MeanFlow"
                        value={typeof this.props.MeanFlow !== "number" ? "--" : Math.round(this.props.MeanFlow as number)}
                        title="MF"
                        uom="mL/min "
                    
                        {...this.props.tileLimits.meanFlow}
            

                //         min: 0,
                //         max: 15
                //     }}
                // badRange = {{
                //         min: 15,
                //         max: 20
                //     }}
                // goodRange = {{
                //         min: 20,
                //         max: 100
                //     }}
                    tileClicked = {
                    this.props.tileClicked
                }
                expanded = {
                    this.props.tilesExpanded
                } > </GaugeTile>
                

                <GaugeTile
                overridename={this.props.overrideName}
                sizeFactor={this.props.ttfmCurves}
                context={this.props.context}
                ref = {this.piGauge}
                key = "PulsalityIndex"
                defaultColor = "tile-redlight"
                value = {
                    typeof this.props.PulsalityIndex !== "number" ? "--" : (this
                        .props
                        .PulsalityIndex as number)
                        .toFixed(1) as any
                }
                title = "PI"
                uom = ""
                maxValue = {
                    99
                }
                reverseValues = {
                    false
                }
                {...this.props.tileLimits.pulsalityIndex}
              
                    tileClicked = {
                    this.props.tileClicked
                }
                expanded = {
                    this.props.tilesExpanded
                } > </GaugeTile> 
                {showCardiacTiles && (
                    <>
                    <GaugeTile
                        overridename={this.props.overrideName}
                        sizeFactor={this.props.ttfmCurves}
                        context={this.props.context}
                        ref = {this.dfGauge}
                        key = "DiastolicFilling"
                        value = { (this.props.usefilling) ?
                            ( typeof this.props.DiastolicFilling !== "number" ? "--" :  Math.round(this.props.DiastolicFilling as number)) : "--" as any
                        }
                        title = "DF"
                        uom = "%"
                        defaultColor = "tile-yellowlight"
                        includeSubValue = {
                            true
                        }
                        reverseValues = {
                            false
                        }
                        worstColor = {
                            "var(--GreenApple)"
                        }
                        badColor = {
                            "var(--GreenApple)"
                        }
                        goodColor = {
                            "var(--BananaClan)"
                        }
                        {...this.props.tileLimits.diastolicFilling}

                        subValue = {
                            this.props.BeatsPerMinute
                                ? ( typeof this.props.BeatsPerMinute !== "number"
                                    ? "--"
                                    : Math.round(this.props.BeatsPerMinute as number))
                                : "--" as any
                        }
                        subValueUom = "BPM" tileClicked = {
                            this.props.tileClicked
                        }
                        expanded = {
                            this.props.tilesExpanded
                        } 
                    /> 
                    <GaugeTile
                        overridename={this.props.overrideName}
                        sizeFactor={this.props.ttfmCurves}
                    context={this.props.context}
                    ref = {this.bfGauge}
                    key = "BackFlow"
                    value = {
                        typeof this.props.BackFlow !== "number"
                        ? "--" : Math.round(this.props.BackFlow as number)
                    }
                    defaultColor = "tile-redlight"
                    worstColor = {
                        "var(--BananaClan)"
                    }
                    title = "BF"
                    uom = "%"
                    reverseValues = {
                        false
                    }
                    {...this.props.tileLimits.backFlow}
                    //  goodRange = {{
                    //          min: 0,
                    //          max: 3
                    //      }}
                    //  badRange = {{
                    //          min: 3,
                    //          max: 5
                    //      }}
                    //  worstRange = {{
                    //          min: 5,
                    //          max: 50
                    //      }}
                        tileClicked = {
                        this.props.tileClicked
                    }
                    expanded = {
                        this.props.tilesExpanded
                    } > </GaugeTile>
                </>
            )}
        
            </>;


            }
    return   <div className={"tile-container "  + (this.props.tileMode === "Gauges" ? " gauge-mode": " tile-mode") + (showCardiacTiles ? " cardiac" : " vascular") } >{tileHolder}</div>;
    }
}

export default TTFMTileHolder;


